import React, { Component } from 'react';
import {Card, Button, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loadNoticiaSecundaria from '../images/loadImage.webp';
import VideoRawsonTurismo from '../components/VideoRawsonTurismo';
import '../pages/styles/Home.css';


const apiUrl = 'https://boletinoficial.rawson.gov.ar';

class NoticiaSecundaria extends Component {

    constructor (props) {
        super(props)
        this.state = {
           noticia : {},
           loading : true,    
           urlImg  : ''            
        }   

    }

    componentDidMount(){
        this.setState({
            noticia: this.props.noticia,
            urlImg : apiUrl + this.props.noticia.imagen[0].url    
            //loading:false
        });
    }

    render() {

  
        if(this.state.noticia.video){
            return(
                <div>
                    <Card>

                        <Card.Header className = "BtncardInfo">
                            {this.state.noticia.titular}
                        </Card.Header>

                        <Card.Body>
                      
                            <div className="container-md">
                                <div className = "row">
                                    <div className="col-md-12">
                                        <VideoRawsonTurismo 
                                            urlVideo = {this.state.noticia.noticia.urlVideo} ancho="100%" alto="20rem"
                                        />
                                    </div>    
                                </div>
                            </div>
                                
                        </Card.Body>

                    </Card> 
                </div>   
            )

        }else{

            return (
                <div>

                    <Card>
                        <Card.Header className = "BtncardInfo">
                            {this.state.noticia.titular} 
                        </Card.Header>

                        <Card.Body>                            
                            <Card.Title>

                                {
                                    this.state.loading &&
                                    <div class="row justify-content-md-center">
                                        <div className="col-md-12" align="center">
                                            <Image
                                                className="img-fluid"
                                                alt="Noticias"
                                                height="30%"
                                                width="30%"
                                           
                                                src={loadNoticiaSecundaria}
                                            />
                                        </div>    
                                    </div>
                                }    

                                <div style={this.state.loading ? {display: 'none'} : {}}>            
                                    <Image
                                        className="img-fluid w-100"
                                        alt="Noticias"
                                        onLoad={()=>this.setState({loading:false})}
                                        src={this.state.urlImg}
                                    />      
                                </div>        
                                

                            </Card.Title>

                            <Card.Text>
                                {this.state.noticia.intro} 
                            </Card.Text>

                            {
                           
                                (this.props.noticia.dataExterna)    
                                ?    
                                    <a href={this.props.noticia.linkDataExterna}>
                                        <Button className = "BtncardInfo visible" variant="info" name="cardNoticiaSec3">     
                                            {this.state.noticia.textButton}
                                        </Button>
                                    </a>
                                :    

                                    ( this.props.noticia.referenciaInterna )
                                    ?
                                        <Link to={this.props.noticia.linkDataExterna}> {/*si no es desarrollo de noticia pero igual apunta a una url interna de la web se aprovecha el Link*/}
                                        
                                            <Button className = "BtncardInfo visible" variant="info" name="cardNoticiaSec3">     
                                                {this.state.noticia.textButton}
                                            </Button>
                                        </Link>
                                    :

                                        <Link to={{pathname:'/noticias_sec/',state:this.state.noticia}}>
                                        
                                            <Button className = "BtncardInfo visible" variant="info" name="cardNoticiaSec3">     
                                                {this.state.noticia.textButton}
                                            </Button>
                                        </Link>
                            }            
                                          
                        </Card.Body>
                    </Card>
                    
                </div>
            )
        }
    }
}

export default NoticiaSecundaria;