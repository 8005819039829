import React, { Component } from 'react';
import '../../pages/styles/Home.css'
import Construccion from "../../images/rentas/construccion.gif";  
import ConstruccionJPG from "../../images/rentas/enConstruccion.jpg" 



class Inmobiliario extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container test">    


                <div className="row justify-content-start" style={{marginTop:20}}>
                      <div className="col"></div>
                      <div className="col-md-8">
                        <h4>Próximamente aquí podrás descargar documentación e información del Impuesto Inmobiliario</h4>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Ingresos Brutos"
                                    src={ConstruccionJPG}
                                    width="90%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>

                  <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba',marginBottom:20}}>

                  <div className="col-md-4 text-center"></div>
                    


          

                    <div className="col-md-4 text-center">
                        <img
                            className="img-fluid mx-auto d-block"
                            alt="Cementerio"
                            src={Construccion}
                            width="90%"
                        />                        
                    </div>







                    <div className="col-md-4 text-center"></div>
                
                  </div>

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default Inmobiliario;