import React, { Component } from 'react';
import {Button,Image} from 'react-bootstrap';

import './styles/Home.css';
import './styles/Rentas.css';
import { animateScroll as scroll  } from 'react-scroll';

import Pcalor from "../components/FamiliaComponents/Pcalor";
import Gestudiante from '../components/FamiliaComponents/Gestudiante';
import Tsocial from "../components/FamiliaComponents/Tsocial";
import Bluz from "../components/FamiliaComponents/Bluz";
import Ppinvalidez from '../components/FamiliaComponents/Ppinvalidez';
import Rpasajes from '../components/FamiliaComponents/Rpasajes';
import Ccomodato from '../components/FamiliaComponents/Ccomodato';
import Ssimbolo from '../components/FamiliaComponents/Ssimbolo';
import Npuerta from '../components/FamiliaComponents/Npuerta';
import Sterreno from '../components/FamiliaComponents/Sterreno';
import Sdomicilios from '../components/FamiliaComponents/Sdomicilios';
import Ssinternacional from '../components/FamiliaComponents/Ssinternacional';

import logoLnc from "../images/logoLnc.webp";

class Rentas extends Component {


    constructor(props){
        super(props);
        this.state={
            loading:true,
            pcalor:true,
            gestudiante:false,    
            tsocial:false,
            bluz:false,
            ppinvalidez:false,
            rpasajes:false,
            ccomodato:false,
            ssimbolo:false,
            npuerta:false,
            sterreno:false,
            sdomicilios:false,
            ssinternacional:false
        }   
    }  

    opciones = {
        pcalor:false,
        gestudiante:false,    
        tsocial:false,
        bluz:false,
        ppinvalidez:false,
        rpasajes:false,
        ccomodato:false,
        ssimbolo:false,
        npuerta:false,
        sterreno:false,
        sdomicilios:false,
        ssinternacional:false
    }
    
    componentDidMount(){  
        scroll.scrollToTop();
    }

    opcionFamilia=(opcion)=>{
        var opciondata = {...this.opciones,[opcion]:true}
        this.setState(opciondata);
    }

    render() {
     
        return (
            <React.Fragment> 
               
                <div className="container-fluid">

                <div className="row headerTramites ">

                        <div className="col-md-2" style={{backgroundColor:'#bbeceb'}}>
                            <div className="row justify-content-center">    
                                <h4 style={{marginLeft:'100%',marginRight:'100%', marginTop:20}}>Trámites</h4>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}} className="btn-block" onClick={()=>this.opcionFamilia('pcalor')}>Plan Calor</Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}} className="btn-block" onClick={()=>this.opcionFamilia('gestudiante')}>Guía del Estudiante</Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('tsocial')}>Tarjeta Social</Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('bluz')}>Bajada de Luz</Button>{' '}   
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('ppinvalidez')}>Pensión Provincial por INVALIDEZ</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('rpasajes')}>Reserva de Pasajes</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('ccomodato')}>Contrato de Comodato</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('ssimbolo')}>Solicitud de Símbolo</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('npuerta')}>Número de Puerta</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('sterreno')}>Solicitud de Terreno</Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('sdomicilios')}>Señalización de frentes de Domicilios</Button>{' '}
                            </div>            
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1,marginBottom:'1.5rem'}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionFamilia('ssinternacional')}>Solicitud de Símbolo Internacional de Acceso</Button>{' '}
                            </div>

                            <div className="row justify-content-center logoNCnone" style={{marginBottom:'1.5rem'}}>
                                <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>
                        </div>
           
                        <div className="col-md-10" >               
                            {this.state.pcalor          && <Pcalor/>}
                            {this.state.gestudiante     && <Gestudiante/>}    
                            {this.state.tsocial         && <Tsocial/>}
                            {this.state.bluz            && <Bluz/>}
                            {this.state.ppinvalidez     && <Ppinvalidez/>}
                            {this.state.rpasajes        && <Rpasajes/>}
                            {this.state.ccomodato       && <Ccomodato/>}
                            {this.state.ssimbolo        && <Ssimbolo/>}
                            {this.state.npuerta         && <Npuerta/>}
                            {this.state.sterreno        && <Sterreno/>}
                            {this.state.sdomicilios     && <Sdomicilios/>}
                            {this.state.ssinternacional && <Ssinternacional/>}
                            <div className="row justify-content-center logoNCtrue" style={{marginBottom:'1.5rem'}}>
                                <Image
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites Produccion"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>           
                        </div>

                
                </div>  
                </div>

            </React.Fragment>      
        )
    }
}

export default Rentas;