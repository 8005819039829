import React, { Component } from 'react';
//import gifTarmites from '../../../images/lotearIcon2.gif'
import logoLotear from '../../../images/lotear/logoLotear.png'
import {Button,Modal,Form} from 'react-bootstrap';
import axios from 'axios';
import {animateScroll as scroll  } from 'react-scroll';
import '../../styles/Home.css';
const url = "https://boletinoficial.rawson.gov.ar/lotears/611fcd2d05dc87000e840720";
const urlLiInfo = "https://boletinoficial.rawson.gov.ar/lotears/63650f2828f7527babd21d8b";
const urlVacantes = "https://boletinoficial.rawson.gov.ar/lotears/63b46772648b91000e44e9a9";
const urlInc = "https://boletinoficial.rawson.gov.ar/lotears/64075c676eb4ca10c72ab0f8";
const urlInt = "https://boletinoficial.rawson.gov.ar/lotears/6419b5e56eb4ca10c72ab1ce";

class LotesDeInversion extends Component {

    constructor (props) {
      super(props)
      this.state = {
        lotesInversionInteresados:'',
        lotesInversionTramiteIncompleto:'',
        lotesDeInversionInformacion:'',
        lotesInversion:'',       
        lotesDeInversionVacantes:''
      }   
    } 

    async componentDidMount(){
      var lotesInversionInteresados = await axios.get(urlInt)
      var lotesInversionTramiteIncompleto = await axios.get(urlInc);
      var lotesDeInversionVacantes = await axios.get(urlVacantes);
      var lotesDeInversionInformacion= await axios.get(urlLiInfo);
      var lotesInversion= await axios.get(url);
     
      this.setState({
        lotesInversionInteresados:lotesInversionInteresados.data.Descripcion,
        lotesInversionTramiteIncompleto:lotesInversionTramiteIncompleto.data.Descripcion,
        lotesDeInversionVacantes:lotesDeInversionVacantes.data.Descripcion,
        lotesDeInversionInformacion:lotesDeInversionInformacion.data.Descripcion,
        lotesInversion:lotesInversion.data.Descripcion,
      })
      scroll.scrollToTop();
    }

    preInscripcion=()=>{
      this.setState({
          show:true,
      })
    }

    handleClose=e=>{
        this.setState({
            show:false
        })
    }

    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Lotes de Inversion"
                                src={logoLotear}
                                width="40%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                        
                                <h4 style={{marginTop:"3rem"}}>Información Lotes de Inversión</h4>
                            
                        </div>
                    </div>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                        <div dangerouslySetInnerHTML={{__html: this.state.lotesInversionInteresados}} /> 
                             
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>

                    <hr></hr>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                        <div dangerouslySetInnerHTML={{__html: this.state.lotesInversionTramiteIncompleto}} /> 
                             
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>

                    <hr></hr>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                        <div dangerouslySetInnerHTML={{__html: this.state.lotesDeInversionVacantes}} /> 
                             
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>


                    <hr></hr>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                        <div dangerouslySetInnerHTML={{__html: this.state.lotesDeInversionInformacion}} /> 
                             
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>

                    <div className="row" style={{'margin-top':'5rem'}}>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 text-center">
                        <Button 
                            style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} 
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/lotesInversion/Sorteo_Inversion.pdf','_blank')}
                        >
                          Listado
                        </Button>
                       
                      </div>
                      <div className="col-md-2"></div>
                    </div>


                    <hr></hr>


                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                        <div dangerouslySetInnerHTML={{__html: this.state.lotesInversion}} /> 
                             
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>

                      <div className="row" style={{'margin-top':'5rem'}}>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 text-center">
                        <Button 
                            style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} 
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/lotesInversion/LISTADO_BENEFICIARIOS.pdf','_blank')}
                        >
                          Listado de Beneficiarios
                        </Button>
                       
                      </div>
                      <div className="col-md-2"></div>
                    </div>


                    <div className="row" style={{'margin-top':'1rem','margin-bottom':'5rem'}}>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 text-center">
                        <Button 
                            style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} 
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/lotesInversion/LISTADO_INVERSION.pdf','_blank')}
                        >
                          Listado sobre él cual se realizó el sorteo
                        </Button>
                       
                        {/*
                          <Modal show={this.state.show} onHide={this.handleClose}>
                    
                            <Modal.Header closeButton>
                                <Modal.Title>ATENCIÓN:</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                                <Form.Group controlId="formBasicCheckbox">
                                    <p>
                                        Ud. esta por completar el formulario de inscripción que tiene calidad de <strong>Declaración Jurada. </strong> 
                                        Lo aquí declarado, llegado el momento, deberá ser acreditado correctamente. 
                                        Por último,  Realizar este trámite <strong>no significa</strong> que Ud, ya es beneficiario de un terreno.
                                    </p>
                                </Form.Group>

                            </Modal.Body>

                            <Modal.Footer>
                                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSddHEkXmMX-5GAYLSiA3Q7J53htdyllcoYwLV9MWfkn0eZA5Q/viewform"}>
                                    <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.handleClose}>    
                                        ACEPTO
                                    </Button>
                                </a>
                                <a href={"https://rawson.gov.ar"}>
                                <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.handleClose}>
                                    RECHAZO
                                </Button>
                                </a>
                            </Modal.Footer> 

                        </Modal>
                        */}
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    
                </div>
            </React.Fragment>  
            
        )
    }
}

export default LotesDeInversion;