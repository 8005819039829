import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import '../../pages/styles/Home.css';
import '../../pages/styles/Rentas.css';
import { GrDownload } from "react-icons/gr";
import ImpGif from "../../images/rentas/imp.gif"


class Ssimbolo extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container familiafade">    

                <div className="row justify-content-center" style={{marginTop:20}}>
                        <div className="col"></div>
                        <div className="col-md-8">
                          <h4>Solicitud de Símbolo, Requisitos y Documentación a presentar.</h4>
                        </div>
                      
                        <div className="col-md-2">
                          <img
                                      className="img-fluid mx-auto d-block"
                                      alt="Impositivas"
                                      src={ImpGif}
                                      width="70%"
                          />                        
                        </div>

                        <div className="col"></div>
                  </div>
    
                  <div className="row justify-content-center" style={{marginTop:50,borderTop:'solid',borderWidth:0.5}}>
       
                    
                      <div className="d-inline p-1">
                        <b style={{fontSize:18}}>DESCARGAR DOCUMENTO CON LOS REQUISITOS</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Familia/Solicitud%20de%20S%C3%ADmbolo%20REQUISITOS%20-%20IMP%20DOBLE%20FAZ.pdf','_blank')}>
                          Descargar
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                    
            
                  </div>
                
              </div>

            </React.Fragment>      
        )
    }
}

export default Ssimbolo;