import React, { Component } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Spinner } from 'react-bootstrap';
import BoletinItem from './boletinItem'
import { FcDownload } from "react-icons/fc";
import { IconContext } from "react-icons";
import PageLoading from '../PageLoading';
import './stylesBoletin.css';
import axios from 'axios';
const ReactMarkdown = require('react-markdown')

const qs = require('qs');

const contentful = require('contentful');

class BoletinOficial extends Component {

    constructor(props) {
        super(props);

        this.anioValue = React.createRef();
        this.mesValue = React.createRef();
        this.numeroValue = React.createRef();
        this.extractoValue = React.createRef();
        this.tipoDocumentoValue = React.createRef();
    

        this.state = {
            loading: false,
            boletines: [],
            boletinesResult: [],
            sinResultados: [],
            desdeNuevaApi: true,
        };
        this.getBoletines();
    }

    boletinesFilter = [];

    getBoletines() {
        var space = process.env.REACT_APP_BOLETIN_SPACE;
        var accessToken = process.env.REACT_APP_BOLETIN_TOKEN;
        this.setState({ loading: true });

        const client = contentful.createClient({
            // This is the space ID. A space is like a project folder in Contentful terms
            space: space,
            // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
            accessToken: accessToken
        });

        client.getEntries({ limit: 1000 }).then((response) => {

            this.setState({
                boletines: response.items,
            })
            this.boletinesFilter = response.items
            //this.setState({ loading: false });

        })
    }

    async handleBuscarBoletin() {

        this.setState({ loading: true });
        this.boletinesFilter = this.state.boletines;

        var descripcion        = '';
        var anioValue          = '';
        var mesValue           = '';
        var mesAnio            = '';
        var numeroValue        = '';
        var numeroAnio         = '';
        var extractoValue      = '';
        var tipoDocumentoValue = '';    
        //--------------año------------------
        anioValue = this.anioValue.current.value
        mesAnio = '/' + anioValue

        //--------------mes------------------
        mesValue = this.mesValue.current.value
        if (mesValue !== '') {
            mesAnio = '/' + mesValue + mesAnio
        }
        //--------------numero---------------
        numeroValue = this.numeroValue.current.value

        //--------------extracto-------------
        extractoValue = this.extractoValue.current.value

        //-------------tipo documento---------
        tipoDocumentoValue = this.tipoDocumentoValue.current.value

        

        if ((anioValue === '') && (mesValue === '') && (numeroValue === '') && (extractoValue === '')) {
            this.setState({ loading: false });
            alert("DEBE INGRESAR OPCIÓN/ES PARA BUSCAR")
        } else if (anioValue === '') {
            this.setState({ loading: false });
            alert("DEBE ELEGIR UN AÑO EN EL QUE BUSCAR");
        } else if ((anioValue !== '') && (mesValue === '') && (numeroValue === '') && (extractoValue === '')) {
            this.setState({ loading: false });
            alert("NO PUEDE ELEGIR SOLO EL AÑO COMO ÚNICA OPCIÓN DEBE ELEGIR COMO MÍNIMO UNA OPCIÓN MÁS - POR EJEMPLO EL MES")
        } else {

            var boletinesResult = [];
            var boletinesResultAux = [];
            var sinResultados = [];

            if ((anioValue === '2021') || (anioValue === '2022') || (anioValue === '2023') || (anioValue === '2024') || (anioValue === '2025')) {

                var query = '';
                var anioCorto = anioValue[2] + anioValue[3];

                if ((mesValue === '') && (numeroValue === '') && (extractoValue !== '') ) {//001

                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento: tipoDocumentoValue}] });
                    }else{
                        query = qs.stringify({ _where: [{ Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}, {Tipo_Documento_ne:'edicto'}] });
                    }

                } else if ((mesValue === '') && (numeroValue !== '') && (extractoValue === '')) {//010

                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Numero_Boletin: numeroValue }, { Anio: anioCorto }, {Tipo_Documento: tipoDocumentoValue}] });
                    }else{
                        query = qs.stringify({ _where: [{ Numero_Boletin: numeroValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}, {Tipo_Documento_ne:'edicto'}] });
                    }

                } else if ((mesValue === '') && (numeroValue !== '') && (extractoValue !== '')) {//011

                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Numero_Boletin: numeroValue }, { Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento: tipoDocumentoValue}] });
                    }else{
                        query = qs.stringify({ _where: [{ Numero_Boletin: numeroValue }, { Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}, {Tipo_Documento_ne:'edicto'}] });
                    }

                } else if ((mesValue !== '') && (numeroValue === '') && (extractoValue === '')) {//100
               
                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Anio: anioCorto }, {Tipo_Documento:tipoDocumentoValue}] });
                    }else{
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}, {Tipo_Documento_ne:'edicto'}] });
                    }
                    
                } else if ((mesValue !== '') && (numeroValue === '') && (extractoValue !== '')) {//101

                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento: tipoDocumentoValue}] });
                    }else{    
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Extracto_contains: extractoValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}] });
                    }

                } else if ((mesValue !== '') && (numeroValue !== '') && (extractoValue === '')) {//110

                    if(tipoDocumentoValue !== 'resoluciones'){
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Numero_Boletin: numeroValue }, { Anio: anioCorto }, {Tipo_Documento: tipoDocumentoValue}] });
                    }else{
                        query = qs.stringify({ _where: [{ Fecha_contains: '/' + mesValue }, { Numero_Boletin: numeroValue }, { Anio: anioCorto }, {Tipo_Documento_ne:'licitaciones'}, {Tipo_Documento_ne:'dictamenes'}] });
                    }
                }
        
                boletinesResultAux = await axios.get(`https://boletinoficial.rawson.gov.ar/boletines?${query}`);
              
                boletinesResult = boletinesResultAux.data;

                if (boletinesResult.length === 0) {
                    sinResultados.push(<BoletinItem key={1} boletin="**SIN RESULTADOS :(**" />);
                    this.setState({
                        sinResultados: sinResultados,
                        boletinesResult: boletinesResult,
                        desdeNuevaApi: false,
                        loading: false
                    })
                } else {
                    this.setState({
                        boletinesResult: boletinesResult,
                        desdeNuevaApi: true,
                        loading: false
                    });
                }

            } else if ((anioValue === '2020') || (anioValue === '2019')) {

                for (let i = 0; i < this.boletinesFilter.length; i++) {

                    descripcion = this.boletinesFilter[i].fields.description;

                    if (descripcion === undefined) {
                        descripcion = '#$%&&##$';
                    }

                    if (numeroValue !== '') {

                        if (anioValue !== '') {
                            numeroAnio = numeroValue + '/' + anioValue[2] + anioValue[3];
                        } else {
                            numeroAnio = numeroValue + '/';
                        }

                        if (extractoValue !== '') {
                            if ((descripcion.indexOf(mesAnio) > 0) && (descripcion.indexOf(mesAnio) < 70) && (descripcion.indexOf(numeroAnio) > 0) && (descripcion.indexOf(extractoValue) > 0)) {
                                boletinesResult.push(<BoletinItem key={i} boletin={this.boletinesFilter[i].fields.description} />);
                            }
                        } else {
                            if ((descripcion.indexOf(mesAnio) > 0) && (descripcion.indexOf(mesAnio) < 70) && (descripcion.indexOf(numeroAnio) > 0)) {
                                boletinesResult.push(<BoletinItem key={i} boletin={this.boletinesFilter[i].fields.description} />);
                            }
                        }

                    } else {
                        if (extractoValue !== '') {
                            if ((descripcion.indexOf(mesAnio) > 0) && (descripcion.indexOf(mesAnio) < 70) && ((descripcion.toLocaleUpperCase().indexOf(extractoValue.toLocaleUpperCase()) > 0))) {
                                boletinesResult.push(<BoletinItem key={i} boletin={this.boletinesFilter[i].fields.description} />);
                            }
                        } else {
                            if ((descripcion.indexOf(mesAnio) > 0) && (descripcion.indexOf(mesAnio) < 70)) {
                                boletinesResult.push(<BoletinItem key={i} boletin={this.boletinesFilter[i].fields.description} />);
                            }
                        }
                    }

                    if (i === this.state.boletines.length - 1) {
                        if (boletinesResult.length === 0 || tipoDocumentoValue === 'licitaciones' || tipoDocumentoValue === 'dictamenes' || tipoDocumentoValue === 'edicto') {
                            sinResultados.push(<BoletinItem key={i} boletin="**SIN RESULTADOS :(**" />);
                            this.setState({
                                sinResultados: sinResultados,
                                boletinesResult: boletinesResult,
                                desdeNuevaApi: false,
                                loading: false
                            })
                        } else {
                            this.setState({
                                boletinesResult: boletinesResult,
                                desdeNuevaApi: false,
                                loading: false
                            });
                        }
                    }
                }
            }
        }
        //this.setState({ loading: false});
    }

    render() {

        if (this.state.desdeNuevaApi) {

            return (

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 titulo" align="center">
                            <h5>SISTEMA DE BÚSQUEDA BOLETÍN OFICIAL</h5>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4" align="left">

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Tipo De Documento</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    ref={this.tipoDocumentoValue}
                                >
                                    <option value="resoluciones">Resoluciones</option>
                                    <option value="licitaciones">licitaciones</option>
                                    <option value="dictamenes">Dictamenes (TC)</option>
                                    <option value="edicto">Edicto</option>
                             
                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Numero</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder=""
                                    aria-label="Texto"
                                    aria-describedby="basic-addon1"
                                    ref={this.numeroValue}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Año</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    onClick={this.getAnio}
                                    ref={this.anioValue}
                                >
                                    <option value=""></option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Mes</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    onClick={this.getmMes}
                                    ref={this.mesValue}
                                >
                                    <option value=""></option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>

                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Extracto</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder=""
                                    aria-label="Texto"
                                    aria-describedby="basic-addon1"
                                    ref={this.extractoValue}
                                />
                            </InputGroup>

                            <Form.Group controlId="exampleForm.ControlInput1">
                                <div>
                                    <Form.Label className="fuente">
                                        <h5>
                                            {this.state.boletinesResult.length}&nbsp;&nbsp;Encontrados
                                        </h5>
                                    </Form.Label>
                                </div>

                                <Card>
                                    <Button className="BtncardInfo fuente" variant="info" onClick={this.handleBuscarBoletin.bind(this)}>
                                        VER
                                    </Button>
                                </Card>
                            </Form.Group>

                        </div>

                        <div className="col-md-8">
                            {

                                this.state.loading
                                    ?
                                    <div className="row">
                                        <div className="col-md-12 titulo" align="center">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>
                                    </div>
                                    :
                                    this.state.boletinesResult.map((boletin) => (
                                        <Card style={{ padding: '1rem', border: 'solid' }} key={boletin.id}>
                                            <Card.Header>
                                                <h4>Boletín N°: {boletin.Numero_Boletin + '/' + boletin.Anio}</h4>
                                                <h4>Fecha del Boletín (dia-mes-año): {boletin.Fecha}</h4>
                                            </Card.Header>
                                            <Card.Body>
                                                <h4>Extracto:</h4>
                                                <ReactMarkdown source={boletin.Extracto} />

                                                <h4>Visualizar o Descargar Digitalizaciones:</h4>
                                                {
                                                    boletin.Imagen_Boletin.map((foja, index) => (
                                                        <Card.Header key={index}>
                                                            <a class="btn btn-success" href={"https://boletinoficial.rawson.gov.ar" + foja.url} target="_blank" rel="noopener noreferrer" role="button">
                                                                Visualizar Escaneo (digital) {index + 1}

                                                                &nbsp;
                                                                &nbsp;
                                                                <FcDownload style={{ color: "white", fontSize: "1.5em" }} />


                                                            </a>

                                                        </Card.Header>

                                                    ))
                                                }
                                            </Card.Body>
                                        </Card>
                                    ))

                            }
                        </div>
                    </div>

                </div>

            )

        } else {

            return (

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 titulo" align="center">
                            <h5>SISTEMA DE BÚSQUEDA BOLETÍN OFICIAL</h5>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4" align="left">

                             <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Tipo De Documento</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    ref={this.tipoDocumentoValue}
                                >
                                    <option value="resoluciones">Resoluciones</option>
                                    <option value="licitaciones">licitaciones</option>
                                    <option value="dictamenes">Dictamenes (TC)</option>
                                    <option value="edicto">Edicto</option>
                             
                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Numero</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder=""
                                    aria-label="Texto"
                                    aria-describedby="basic-addon1"
                                    ref={this.numeroValue}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Año</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    onClick={this.getAnio}
                                    ref={this.anioValue}
                                >
                                    <option value=""></option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2023">2024</option>
                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Mes</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select" custom
                                    onClick={this.getmMes}
                                    ref={this.mesValue}
                                >
                                    <option value=""></option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>

                                </Form.Control>
                            </InputGroup>

                            <InputGroup className="mb-3 fuente">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Extracto</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder=""
                                    aria-label="Texto"
                                    aria-describedby="basic-addon1"
                                    ref={this.extractoValue}
                                />
                            </InputGroup>

                            <Form.Group controlId="exampleForm.ControlInput1">
                                <div>
                                    <Form.Label className="fuente">
                                        <h5>
                                            {this.state.boletinesResult.length}&nbsp;&nbsp;Encontrados
                                        </h5>
                                    </Form.Label>
                                </div>

                                <Card>
                                    <Button className="BtncardInfo fuente" variant="info" onClick={this.handleBuscarBoletin.bind(this)}>
                                        VER
                                    </Button>
                                </Card>

                            </Form.Group>

                        </div>

                        <div className="col-md-8">
                            {this.state.boletinesResult.length === 0 ? this.state.sinResultados : this.state.boletinesResult}
                        </div>
                    </div>

                </div>

            )
        }

    }
}

export default BoletinOficial;