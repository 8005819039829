import React, { Component } from 'react';
import gifTarmites from '../../../images/tramites.webp';
import {Card, Button, Accordion} from 'react-bootstrap';
import '../../styles/Home.css';
import '../linksCustomize.css';

class GuiaTramites extends Component {
    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTarmites}
                                width="15%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                        
                                <h5 style={{marginTop:"3rem"}}> Guía de Trámites Unidad Ejecutora Municipal</h5>
                            
                        </div>
                    </div>
                    
                    <div className="row" style={{'margin-top':'2rem','margin-bottom':'2rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-center">
                            <h5>Registro Municipal De Constructores De Obras Públicas</h5>
                           
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row" style={{'margin-top':'1rem','margin-bottom':'1rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <h6>
                                Se encuentra habilitada la inscripción en el REGISTRO MUNICIPAL DE CONSTRUCTORES DE OBRAS PÚBLICAS en las siguientes categorías:   
                            </h6>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row" style={{'margin-top':'2rem','margin-bottom':'2rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8" style={{'background':'white','padding':20}}>
                          
                            <ul>
                                <li>
                                    <p>A: Empresas con capacidad de ejecución otorgada por el Registro Provincial</p>
                                </li>
                                <li>
                                    <p>B: Empresas locales no inscriptas en el Registro Provincial</p>
                                </li>
                                <li>
                                    <p>C: Cooperativas de trabajo en el rubro construcción</p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row" style={{'margin-top':'1rem','margin-bottom':'1rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-center">
                          
                            <b>El trámite es virtual. El Registro no realiza atención presencial.</b>
                              
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h5>Descargas</h5>
                                           
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <ul>
                                            <li>
                                                <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/op/INSTRUCTIVO SOLICITUD DE INSCRIPCION O RENOVACION.pdf">
                                                    Descargar Instructivo del trámite.
                                                </a>
                                                </li>
                                                <li>
                                                <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/op/LEGAJO RMC.doc">
                                                    Descargar Formularios del legajo a conformar para inscripción o renovación.
                                                </a>
                                               
                                                </li>
                                              
                                        
                                        
                                        </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                              
                            </Accordion>

                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </React.Fragment>  
            
        )
    }
}

export default GuiaTramites;