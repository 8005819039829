import React, { Component } from 'react';
import {Form,Carousel,Spinner,Image} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';

class EducAmb extends Component {

  
    constructor(props){
      super(props);
      this.state={
        loading:true,
        verOrdenanzas:true,
        verPlasticos:false,
        verCambios:false,  
        verColilleros:false,
        verCompostajes:false,
      
      } 
    }  

    mostrarOpcion=(valor)=>{
      if(valor==='Ordenanzas'){
        this.setState({
          loading:true,
          verOrdenanzas:true,
          verPlasticos:false,
          verCambios:false,  
          verColilleros:false,
          verCompostajes:false,
        })
      }else if(valor==='Plásticos de un Solo Uso'){
        this.setState({
          loading:true,
          verOrdenanzas:false,
          verPlasticos:true,
          verCambios:false,  
          verColilleros:false,
          verCompostajes:false,
        })  

      }else if(valor==='Seamos Actores del Cambio'){
        this.setState({
          loading:true,
          verOrdenanzas:false,
          verPlasticos:false,
          verCambios:true,  
          verColilleros:false,
          verCompostajes:false,
        })
      }else if(valor==='Colilleros'){
        this.setState({
          loading:true,
          verOrdenanzas:false,
          verPlasticos:false,
          verCambios:false,  
          verColilleros:true,
          verCompostajes:false,
        })
      }else if(valor==='Compostajes'){
        this.setState({
          loading:true,
          verOrdenanzas:false,
          verPlasticos:false,
          verCambios:false,  
          verColilleros:false,
          verCompostajes:true,
        })
      }
    }

    

    render() {
        return (
            <React.Fragment> 
              <div className="row justify-content-center">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center" style={{marginTop:'1rem'}}>
                  <Form>  
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control as="select" onChange={(e)=>{this.mostrarOpcion(e.target.value)}}>
                        <option name="ord">Ordenanzas</option>
                        <option name='plasticos'>Plásticos de un Solo Uso</option>
                        <option name='cam'>Seamos Actores del Cambio</option>
                        <option name='col'>Colilleros</option>
                        <option name='comp'>Compostajes</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </div>
                <div className="col-md-2"></div>
              </div>

              <div>    

                  <div className="row justify-content-center test">

                    <div className="col"></div> 

                    <div className="col-sm-12 col-md-10 col-lg-8 text-center">

                      {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}
                      <div style={this.state.loading ? {display: 'none'} : {}}>
                      {      
                      this.state.verOrdenanzas&&<Carousel  className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_copia_f755e9d146.jpg?3178371.9', '_blank')}
                            onLoad={()=>this.setState({loading:false})}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_copia_f755e9d146.jpg?3178371.9"
                            alt="First slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_copia_copia_7628e58997.jpg?3283288.6999999997', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_copia_copia_7628e58997.jpg?3283288.6999999997"
                            alt="Second slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_PUNZANTES_3a9b3d8558.jpg?3350962.1', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_PUNZANTES_3a9b3d8558.jpg?3350962.1"
                            alt="Second slide"
                          />
                        </Carousel.Item>

                      </Carousel>
                      }

                      {this.state.verPlasticos&&<Carousel  className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_AMBAS_ORDENANZAS_QUE_BUSCAN_Y_COMO_ACTUAR_c660b599cb.jpg?60927.199999999255', '_blank')}
                          onLoad={()=>this.setState({loading:false})}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_AMBAS_ORDENANZAS_QUE_BUSCAN_Y_COMO_ACTUAR_c660b599cb.jpg?60927.199999999255"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_COMO_PONDEMOS_RECICLAR_ESTOS_PLASTICOS_336f80538b.jpg?254238', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_COMO_PONDEMOS_RECICLAR_ESTOS_PLASTICOS_336f80538b.jpg?254238"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_ECOBOLSAS_5620a2fa73.jpg?309870', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_ECOBOLSAS_5620a2fa73.jpg?309870"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_QUE_RECICLAR_57fc592447.jpg?340477.5', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_QUE_RECICLAR_57fc592447.jpg?340477.5"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_WRV_3_ae5bf74015.jpg?380443.69999999925', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_WRV_3_ae5bf74015.jpg?380443.69999999925"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_57515c638f.jpg?415263.5999999996', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_57515c638f.jpg?415263.5999999996"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_BOTELLAS_aa1d5c8e21.jpg?446656', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_BOTELLAS_aa1d5c8e21.jpg?446656"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_COMO_PONDEMOS_RECICLAR_ESTOS_PLASTICOS_copia_copia_de63d4eed1.jpg?487922.0999999996', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_COMO_PONDEMOS_RECICLAR_ESTOS_PLASTICOS_copia_copia_de63d4eed1.jpg?487922.0999999996"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_92e746d7c9.jpg?519857', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_PLASTICOS_1_SOLO_USO_ORDENANZA_QUE_BROHIBE_copia_92e746d7c9.jpg?519857"
                          alt="Second slide"
                        />
                      </Carousel.Item>

                   

                      </Carousel>
                      }

                      {this.state.verCambios&&<Carousel  className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_LAS_3_R_2_eb3549fd49.jpg?1557560.0999999996', '_blank')}
                          onLoad={()=>this.setState({loading:false})}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_LAS_3_R_2_eb3549fd49.jpg?1557560.0999999996"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_COMO_SEPARAR_7_bc8202025e.jpg?1598576.8000000007', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_COMO_SEPARAR_7_bc8202025e.jpg?1598576.8000000007"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_RECORRIDO_BASURA_f99c993334.jpg?1651702.4000000004', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_RECORRIDO_BASURA_f99c993334.jpg?1651702.4000000004"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_Huella_ecologica_y_de_carbono_2a33515e21.png?1678495.4000000004', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_Huella_ecologica_y_de_carbono_2a33515e21.png?1678495.4000000004"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_agua_potable_0e63b5dacc.jpg?1711856.1999999993', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_agua_potable_0e63b5dacc.jpg?1711856.1999999993"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_cuanta_basura_genera_rawson_d0efd4cb70.png?1738440.6999999993', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_cuanta_basura_genera_rawson_d0efd4cb70.png?1738440.6999999993"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_2_modelo_fn_be14845356.png?1770926.5', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_2_modelo_fn_be14845356.png?1770926.5"
                          alt="First slide"
                        />
                      </Carousel.Item>

                      </Carousel>
                      }

                      {this.state.verColilleros&&<Carousel  className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_ubicacion_coilleros_2_e1087a0472.jpg?2115229.3000000007', '_blank')}
                            onLoad={()=>this.setState({loading:false})}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_ubicacion_coilleros_2_e1087a0472.jpg?2115229.3000000007"
                            alt="First slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_ubicacion_colilleros_00eea94b24.jpg?2317615.8000000007', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_ubicacion_colilleros_00eea94b24.jpg?2317615.8000000007"
                            alt="Second slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_COLILLERO_MATIAS_9ab90bba13.jpg?2349398', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_COLILLERO_MATIAS_9ab90bba13.jpg?2349398"
                            alt="Second slide"
                          />
                        </Carousel.Item>

                        </Carousel>
                      }

                      {this.state.verCompostajes&&<Carousel  className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_Motivos_363fe83ece.jpg?2401349.0999999996', '_blank')}
                            onLoad={()=>this.setState({loading:false})}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_Motivos_363fe83ece.jpg?2401349.0999999996"
                            alt="First slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_Proceso_fac578ca8e.jpg?2451438', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_Proceso_fac578ca8e.jpg?2451438"
                            alt="Second slide"
                          />
                        </Carousel.Item>

                        </Carousel>
                      }
                      </div>
                      

                    </div>

                    <div className="col"></div>    

                  </div>
              </div>

            </React.Fragment>      
        )
    }
}

export default EducAmb;