import React, { Component } from 'react';
import {Button, Image} from 'react-bootstrap';

import './styles/Home.css';
import './styles/Rentas.css';


import { BsTrash } from "react-icons/bs";
import  {GiTreeBranch } from "react-icons/gi";
import { GoBook } from "react-icons/go";
import { MdRadio } from "react-icons/md";
import { TiNews } from "react-icons/ti";

import {animateScroll as scroll  } from 'react-scroll';

import CertyA from "../components/ProduccionComponents/CertificadoYarbol";
import RecDif from "../components/ProduccionComponents/RecoleccionDif";
import ArbP from "../components/ProduccionComponents/ArboladoPublico";
import EdAmb from "../components/ProduccionComponents/EducacionAmbiental";
import RadAmb from "../components/ProduccionComponents/RadioAmbiental";

import logoLnc from "../images/logoLnc.webp";

class Produccion extends Component {


    constructor(props){
        super(props);
        this.state={
          loading:true,
          certya:true,  
          recdif:false,//
          arbp:false,  
          edamb:false,
          raamb:false,
         
        } 
    }  
    componentDidMount(){scroll.scrollToTop();}

    opcionProduccion=(opcion)=>{
        if(opcion==='ca'){
            this.setState({
                certya:true,
                recdif:false,//true
                arbp:false,  
                edamb:false,
                raamb:false,           
            })
        }else if(opcion==='rf'){
            this.setState({
                certya:false,
                recdif:true,//true
                arbp:false,  
                edamb:false,
                raamb:false,           
            })
        }else if(opcion==='ap'){
            this.setState({
                certya:false,        
                recdif:false,
                arbp:true,  
                edamb:false,
                raamb:false,            
            })
            
        }else if(opcion==='ea'){
            this.setState({
                certya:false,
                recdif:false,
                arbp:false,  
                edamb:true,
                raamb:false,    
            })
            
        }else if(opcion==='ra'){
            this.setState({
                certya:false,
                recdif:false,
                arbp:false,  
                edamb:false,
                raamb:true,
            })    
        }
    }

    render() {
        return (
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row headerTramites ">
                        <div className="col-sm-12 col-md-2" style={{backgroundColor:'#C0EAAE',borderTop:'solid',borderBottom:'solid', borderWidth:2,borderColor:'green'}}>
                            <div className="row justify-content-center">                     
                                <h5 style={{marginTop:20}}>Información</h5>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button
                                    variant="success"  
                                    style={{borderRadius:30}} 
                                    className="btn-block" 
                                    onClick={()=>this.opcionProduccion('ca')}
                                >
                                    Novedades<br></br><TiNews size='1.4em' color='green'/>
                                </Button>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button
                                    variant="success"  
                                    style={{borderRadius:30}} 
                                    className="btn-block" 
                                    onClick={()=>this.opcionProduccion('rf')}
                                >
                                    Recolección Diferenciada<br></br><BsTrash size='1.4em' color='#9c4517'/>
                                </Button>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button 
                                    variant="success" 
                                    style={{borderRadius:30}}  
                                    className="btn-block" 
                                    onClick={()=>this.opcionProduccion('ap')}
                                >
                                    Arbolado Público <br></br><GiTreeBranch size='1.4em' color='#304B25'/>
                                </Button>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button 
                                    variant="success" 
                                    style={{borderRadius:30}}  
                                    className="btn-block" 
                                    onClick={()=>this.opcionProduccion('ea')}
                                >
                                    Educación Ambiental <br></br><GoBook size='1.5em' />
                                </Button>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1, marginBottom:'2.5rem'}}>
                                <Button 
                                    variant="success" 
                                    style={{borderRadius:30}}  
                                    className="btn-block" 
                                    onClick={()=>this.opcionProduccion('ra')}
                                >
                                    Radio Ambiental <br></br><MdRadio size='1.5em' color='#B6082A'/>
                                </Button>
                            </div>

                            <div className="row justify-content-center logoNCnone" style={{marginBottom:'1.5rem'}}>
                                <Image
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites Produccion"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-10 borderNone" style={{backgroundColor:'#C0EAAE',borderStyle:'solid',borderWidth:2,borderColor:'green',}}>               
                            {this.state.certya && <CertyA/>}    
                            {this.state.recdif && <RecDif/>}
                            {this.state.arbp && <ArbP />}
                            {this.state.edamb && <EdAmb/>}
                            {this.state.raamb && <RadAmb/>}   
                            <div className="row justify-content-center logoNCtrue" style={{marginBottom:'1.5rem'}}>
                                <Image
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites Produccion"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>                    
                        </div>

                       
                    </div>  
                   
                </div>
            </React.Fragment>      
        )
    }
}

export default Produccion;