import React, { Component } from 'react';
import logoLotear from '../images/lotear/logoLotear.png';
import {Button, Card} from 'react-bootstrap';
import './styles/Home.css';
import { FcDownload } from "react-icons/fc";
import {animateScroll as scroll  } from 'react-scroll';

class Lotear extends Component {
    
    constructor(props){
        super(props);
        this.state={
            show:false,
            msjLi:false
        }
    }

    componentDidMount(){scroll.scrollToTop();}

    preInscripcion=()=>{
        this.setState({
            show:true,
        })
    }

    handleClose=e=>{
        this.setState({
            show:false
        })
    }

    render() {
        return (
        
            <React.Fragment> 
                <div className="container-fluid"> 

                    <div className="row headerTramites">
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Lotear"
                                src={logoLotear}
                                width="40%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                        
                                <h3 style={{marginTop:"3rem"}}> Programa Lotear Información</h3>
                            
                        </div>
                    </div>  

                    <div className="row" style={{background:"white"}}>
                        <div className="col-md-1"></div>

                        <div className="col-md-10" style={{marginBottom:"2rem"}}>

                            <h5 className="text-center" style={{marginTop:"2rem", marginBottom:"1rem"}}>
                                <strong>
                                    Resultados del Sorteo del Programa Lotear.
                                </strong>
                            </h5>
                       
                        </div>

                        <div className="col-md-1"></div>
                    </div>

                </div>

                <div className="container-fluid palacio">
                    <div className="col-lg-12"> 

                        <div className="row">

                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                    <Card className="cardAlto">
                                        <Card.Header className = "BtncardInfo text-center">
                                            <h5>Resultados Sorteo Programa Lotear del día 23 de marzo del 2022</h5>
                                        </Card.Header>
                                        <Card.Body>    
                                            <Card.Title className="contenedor">

                                                <div className="col-md-12 text-center">

                                                    <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                        <div className="col-md-7">
                                                            <b style={{fontSize:15}}>Linea Social sorteo de Suplentes</b>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Button 
                                                                style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                                className="btn-block"
                                                                onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo23032022/socialSuplentes_23032022.pdf','_blank')}>
                                                                Descargar
                                                                <FcDownload size='1.5em'/>
                                                            </Button>
                                                        </div>    
                                                    </div>

                                                    <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                        <div className="col-md-7">
                                                            <b style={{fontSize:15}}>Linea Intermedia sorteo de Suplentes</b>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Button 
                                                                style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                                className="btn-block"
                                                                onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo23032022/intermediaSuplentes_23032022.pdf','_blank')}>
                                                                Descargar
                                                                <FcDownload size='1.5em'/>
                                                            </Button>
                                                        </div>   
                                                    </div>

                                                    <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    
                                                    </div>
                                                </div>             
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                            </div>

                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                    <Card className="cardAlto">
                                        <Card.Header className = "BtncardInfo text-center">
                                            <h5>Resultados Sorteo Programa Lotear del día 22 de marzo del 2022</h5>
                                        </Card.Header>
                                        <Card.Body>    
                                            <Card.Title className="contenedor">

                                                <div className="col-md-12 text-center">

                                                    <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                        <div className="col-md-7">
                                                            <b style={{fontSize:15}}>Linea Social sorteo Grupo 9</b>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Button 
                                                                style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                                className="btn-block"
                                                                onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo22032022/socialGrupo9_22032022.pdf','_blank')}>
                                                                Descargar
                                                                <FcDownload size='1.5em'/>
                                                            </Button>
                                                        </div>    
                                                    </div>

                                                    <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                        <div className="col-md-7">
                                                            <b style={{fontSize:15}}>Linea Social sorteo Grupo 10</b>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Button 
                                                                style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                                className="btn-block"
                                                                onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo22032022/socialGrupo10_22032022.pdf','_blank')}>
                                                                Descargar
                                                                <FcDownload size='1.5em'/>
                                                            </Button>
                                                        </div>   
                                                    </div>

                                                    <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    
                                                    </div>
                                                </div>             
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                            </div>

                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Resultados Sorteo Programa Lotear del día 21 de marzo del 2022</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">

                                            <div className="col-md-12 text-center">

                                                <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 7</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo21032022/socialGrupo7_21032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>    
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 8</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo21032022/socialGrupo8_21032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>   
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Intermedia sorteo Grupo 4</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo21032022/intermediaGrupo4_21032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>        
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>             
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            

                        </div>

                        <div className="row">

                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Resultados Sorteo Programa Lotear del día 18 de marzo del 2022</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">

                                            <div className="col-md-12 text-center">

                                                <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 5</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo18032022/socialGrupo5_18032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>    
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 6</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo18032022/socialGrupo6_18032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>   
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Intermedia sorteo Grupo 3</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo18032022/intermediaGrupo3_18032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>        
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>             
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Resultados Sorteo Programa Lotear del día 17 de marzo del 2022</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">

                                            <div className="col-md-12 text-center">

                                                <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 3</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo17032022/socialGrupo3_17032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>    
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 4</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo17032022/socialGrupo4_17032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>   
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Intermedia sorteo Grupo 2</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo17032022/intermediaGrupo2_17032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>        
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>             
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                           
                            <div className="col-md-4" style={{padding:"1rem"}}>        
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Resultados Sorteo Programa Lotear del día 16 de marzo del 2022</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">

                                            <div className="col-md-12 text-center">

                                                <div className="row align-items-center" style={{padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 1</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo16032022/socialGrupo1_16032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>    
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Social sorteo Grupo 2</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo16032022/socialGrupo2_16032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>   
                                                </div>

                                                <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                                                    <div className="col-md-7">
                                                        <b style={{fontSize:15}}>Linea Intermedia sorteo Grupo 1</b>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <Button 
                                                            style={{borderRadius:50,backgroundColor:'#58ba9c',color:'black',fontSize:14}}
                                                            className="btn-block"
                                                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/sorteo16032022/intermediaGrupo1_16032022.pdf','_blank')}>
                                                            Descargar
                                                            <FcDownload size='1.5em'/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>             
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>   
                            
                        
                        </div>






                   


                        <div className="row" style={{background:'white',marginBottom:'-7rem',marginTop:'9rem'}}>

                            <div className="col-md-3"></div>

                            <div className="col-md-6 text-center" style={{background:'white',marginTop:'4rem'}}>

                                <div style={{borderStyle:'solid',borderWidth:'1px',borderRadius:'0.8rem',padding:'1rem',marginBottom:'3rem'}}>
                                    <strong>En esta sección se encuentran disponibles para descargar los listados generales definitivos sobre los cuales se realizan los sorteos.</strong>   
                                        
                                    <Button 
                                        style={{fontSize:15,borderRadius:50,backgroundColor:'#58ba9c',marginTop:"2rem",marginBottom:"2rem",color:"black",boxShadow:'4px 4px 4px 1px rgba(0, 0, 0, 0.2)'}}
                                        className="btn-block"
                                        onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/SocialDef.pdf','_blank')}>
                                            DESCARGAR LISTADO DEFINITIVO LOTEO SOCIAL
                                        <FcDownload size='1.5em'/>
                                    </Button>

                                    <Button 
                                        style={{fontSize:15,borderRadius:50,backgroundColor:'#58ba9c',marginBottom:"3rem",color:"black",boxShadow:'4px 4px 4px 1px rgba(0, 0, 0, 0.2)'}}
                                        className="btn-block"
                                        onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/listadoLotear/IntermediaDef.pdf','_blank')}>
                                            DESCARGAR LISTADO DEFINITIVO LINEA INTERMEDIA
                                        <FcDownload size='1.5em'/>
                                    </Button>
                                </div>

                            </div>

                            <div className="col-md-3"></div>

                        </div>




                    

                    </div>
                </div>
            </React.Fragment>  
            
        )
    }
}

export default Lotear;