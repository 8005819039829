import React, { Component } from 'react';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';
import Autos from "../../images/transito/autos.gif"; 

class Bluz extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container familiafade">    

                <div className="row justify-content-center" style={{marginTop:'1rem'}}>
                      <div className="col"></div>
                      <div className="col-md-8 text-center">
                        <h5>Requisitos para la licencia particular y profesional clase: D1-D2-D3-D4</h5>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                          className="img-fluid mx-auto d-block"
                          alt="Transito"
                          src={Autos}
                          width="90%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>

                <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba',padding:'1rem'}}>
                  <div className="col">
                    <ul class="list-group">
                      <li class="list-group-item"><b>1</b>-Presentar DNI y presentar copia del mismo donde conste datos personales y útimo domicilio actualizado.</li>
                      <li class="list-group-item"><b>2</b>-Certificado de antecedentes de tránsito extendido por REPAT (Terminal de ómnibus Rawson).</li>
                      <li class="list-group-item"><b>3</b>-Libre de deuda de infracciones por el tribunal de faltas (Av. Sarmiento N°974).</li>
                      <li class="list-group-item"><b>4</b>-Carnet anterior en mano - certificado autent. de origen.</li>
                      <li class="list-group-item"><b>5</b>-Certificado de reincidencia psicológica cat. D-1-D-2-D-3.</li>
                      <li class="list-group-item"><b>6</b>-Grupo sanguíneo.</li>
                      <li class="list-group-item"><b>7</b>-Número de CUIL.</li>
                    </ul>
                  </div>
                </div>
              </div>

            </React.Fragment>      
        )
    }
}

export default Bluz;