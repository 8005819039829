import React, { Component } from 'react'
import logoMuni from '../../images/escudoMuni.webp';
import {Button,Spinner} from 'react-bootstrap';
import axios from 'axios';
import './personal.css';
import {animateScroll as scroll  } from 'react-scroll';



const urlInfoPersonal = "https://boletinoficial.rawson.gov.ar/lotears/62f66aa0a7f198000e68f281";



class Personal extends Component {
    
    constructor (props) {
      super(props)
      this.state = {
        infoLoteoSocial:'',      
        lotesVivienda:'',
        loading:true
      }   
     
    } 

   

    componentDidMount(){
      this.getLineas(); 
    }  
      
    getLineas=async()=>{
        var infoPersonal= await axios.get(urlInfoPersonal);
        this.setState({
            infoPersonal:infoPersonal.data.Descripcion,
            loading:false        
        })
        
        scroll.scrollToTop();  
    }    
       
    preInscripcion=()=>{
        this.setState({
            show:true,
        })
    }

    handleClose=e=>{
        this.setState({
            show:false
        })
    }

    render() {
        
       
        return (
            this.state.loading
            ?
                <div className="container">
                    <div className="row">    
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-center">
                            <Spinner animation="border" variant="info" style={{marginTop:'2rem',marginBottom:'3rem'}}/>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            :
                <React.Fragment>    
                    <div className="container-fluid">
                        <div className="row" style={{background:'white'}}>
                            <div className="col-md-6">
                                <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Loteo Social"
                                    src={logoMuni}
                                    width="15%"
                                    style={{'padding':'8px'}}
                                />
                            </div>
                            <div className="col-md-6 text-center">
                            <h4 style={{marginTop:"3rem"}}>Dirección de Personal</h4> 
                            </div>
                        </div>

                        <div className="row styleTextDesarrollo" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                            <div className="col-md-2"></div>
                            <div className="col-md-8">


                            <div dangerouslySetInnerHTML={{__html: this.state.infoPersonal}} />   
                    
                                
                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>

                        <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-center">
                
                            <a target="_blank" rel="noopener noreferrer" href={"https://docs.google.com/forms/d/e/1FAIpQLSc1t5IZ_z17jhc-ZZZvRWh9uTMspLt9LmLZ-L2RCiZKcW8FnQ/viewform"}>
                                <Button 
                                    style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} 
                                    onClick={this.preInscripcion}
                                >
                                    Formulario Para la Declaración Jurada
                                </Button>
                            </a>               

                        </div>
                        <div className="col-md-2"></div>
                        
                        </div>
                        
                    </div>
                </React.Fragment>   
        )
    }
}

export default Personal;