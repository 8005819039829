import React, { Component } from 'react';
import {Button,Image} from 'react-bootstrap';

import './styles/Home.css';
import './styles/Rentas.css';
import { animateScroll as scroll  } from 'react-scroll';

import C1 from "../components/TransitoComponents/c1";
import C2 from "../components/TransitoComponents/c2";
import C3 from "../components/TransitoComponents/c3";
import C4 from '../components/TransitoComponents/c4';

import logoLnc from "../images/logoLnc.webp";

class Transito extends Component {

    constructor(props){
        super(props);
        this.state={
            loading:true,
            c1:true,
            c2:false,
            c3:false,
            c4:false,
        }   
    }  

    opciones = {
        c1:false,
        c2:false,
        c3:false,
        c4:false,
    }
    
    componentDidMount(){  
        scroll.scrollToTop();
    }

    opcionTransito=(opcion)=>{
        var opciondata = {...this.opciones,[opcion]:true}
        this.setState(opciondata);
    }

    render() {
     
        return (
            <React.Fragment> 
               
                <div className="container-fluid">
                    <div className="row headerTramites ">

                        <div className="col-md-2" style={{backgroundColor:'#bbeceb'}}>
                            <div className="row justify-content-center">    
                                <h4 style={{marginLeft:'100%',marginRight:'100%', marginTop:20}}>Trámites</h4>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}} className="btn-block" onClick={()=>this.opcionTransito('c1')}>Requisitos Licencia CLASE A-B1...</Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionTransito('c2')}>Requisitos Licencia CLASE D1-D2...</Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionTransito('c3')}>Licencia Clases y Subclases</Button>{' '}   
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1,marginBottom:'1.5rem'}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionTransito('c4')}>Autorización de los padres de menores</Button>{' '}
                            </div>

                            <div className="row justify-content-center logoNCnone" style={{marginBottom:'1.5rem'}}>
                                <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>
                        </div>
        
                        <div className="col-md-10" >               
                            {this.state.c1 && <C1/>}
                            {this.state.c2 && <C2/>}
                            {this.state.c3 && <C3/>}
                            {this.state.c4 && <C4/>}
                            <div className="row justify-content-center logoNCtrue" style={{marginBottom:'1.5rem'}}>
                                <Image
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites Produccion"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>           
                        </div>

                    
                    </div>  
                </div>

            </React.Fragment>      
        )
    }
}

export default Transito;