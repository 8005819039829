import React from 'react';
import { Link } from 'react-router-dom';
import logoRwNavBar from '../images/escudoMuni.webp';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import './styles/Navbar.css';

class NavbarRw extends React.Component {
 
 
  render() {
    return (
 
        <Navbar className="rawsonNavBAr" bg="light" expand="lg">
        
          <Navbar.Brand href="#home">
            <img className="img-responsive" 
              src={logoRwNavBar} 
              alt="Logo"
              width="40rem" 
            />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">

              <a href={"https://rawson.gov.ar"} style={{textDecoration:'none', color:'black', marginLeft:'1rem'}}>
                Inicio
              </a>

              {/*  
              <Nav.Link as={Link} to="/guia_tramites" className="linkNoneDec">
                Trámites
              </Nav.Link>
        
              <NavDropdown title={<span className="linkNoneDec">Transparencia</span>}>
                <NavDropdown.Item id="opcionDropDown" as={Link} to="/gabinete" className="linkNoneDec">Gestión Damián Biss</NavDropdown.Item>
                <NavDropdown.Item id="opcionDropDown" as={Link} to="/boletin" className="linkNoneDec">Boletín Oficial</NavDropdown.Item>
        
              </NavDropdown>
        

              <Nav.Link><Link to="/impuestos" className="linkNoneDec">Pague sus Impuestos</Link></Nav.Link>*/}
              
            </Nav>
        
             
            
            
            {/*
            <Navbar.Brand href="https://www.facebook.com/munirawson">
              <FaFacebookSquare color="F0F9F7" size="1.5rem"/>
            </Navbar.Brand>
            */}
          </Navbar.Collapse>
            
        </Navbar>
        
        
        
    );
  }
}

export default NavbarRw;
