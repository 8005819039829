import React, { Component } from 'react';
import {Carousel, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loadCarruselImg from '../images/loadComprimidos/bannerLoad.webp';
import './styles/Badge.css';
const url = "https://boletinoficial.rawson.gov.ar";


export default class CarruselNoticias extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading:true,
    };
  }
 
  
  render() {
    return (

      <>
        {this.state.loading && 
          <Image
                className="img-fluid w-100 mx-auto d-block fadeHome"
                rounded='true'
                src={loadCarruselImg}
                alt="Noticia Municipalidad de Rawson Chubut"
          />
        }

        <div style={this.state.loading ? {display: 'none'} : {}}>
          <Carousel>
            
                <Carousel.Item key={0}>
                  <Link to={{pathname:`/noticias/0`,state:{data:this.props.noticiasCarrouselData}}}>
                    {
                      <Image
                        className="img-fluid w-100 mx-auto d-block fadeHome"
                        rounded='true'
                        //onLoad={()=>this.setState({loading:false})}
                        src={url+this.props.noticiasCarrouselData[0].Imagen_Carrusel.formats.small.url}
                        alt="Noticia Municipalidad de Rawson Chubut"
                      />
                    }     
                  </Link>
                  <Carousel.Caption>
                    <h4 className='titularCarousel'>
                      <strong>
                        {this.props.noticiasCarrouselData[0].Titular}
                      </strong>
                    </h4>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item key={1}>
                  <Link to={{pathname:`/noticias/1`,state:{data:this.props.noticiasCarrouselData}}}>
                    {
                      <Image
                        className="img-fluid w-100 mx-auto d-block fadeHome"
                        rounded='true'
                        //onLoad={()=>this.setState({loading:false})}
                        src={url+this.props.noticiasCarrouselData[1].Imagen_Carrusel.formats.small.url}
                        alt="Noticia Municipalidad de Rawson Chubut"
                      />
                    }     
                  </Link>
                  <Carousel.Caption>
                    <h4 className='titularCarousel'>
                      <strong>
                        {this.props.noticiasCarrouselData[1].Titular}
                      </strong>
                    </h4>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item key={2}>
                  <Link to={{pathname:`/noticias/2`,state:{data:this.props.noticiasCarrouselData}}}>
                    {
                      <Image
                        className="img-fluid w-100 mx-auto d-block fadeHome"
                        rounded='true'
                        //onLoad={()=>this.setState({loading:false})}
                        src={url+this.props.noticiasCarrouselData[2].Imagen_Carrusel.formats.small.url}
                        alt="Noticia Municipalidad de Rawson Chubut"
                      />
                    }     
                  </Link>
                  <Carousel.Caption>
                    <h4 className='titularCarousel'>
                      <strong>
                        {this.props.noticiasCarrouselData[2].Titular}
                      </strong>
                    </h4>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item key={3}>
                  <Link to={{pathname:`/noticias/3`,state:{data:this.props.noticiasCarrouselData}}}>
                    {
                      <Image
                        className="img-fluid w-100 mx-auto d-block fadeHome"
                        rounded='true'
                        onLoad={()=>this.setState({loading:false})}
                        src={url+this.props.noticiasCarrouselData[3].Imagen_Carrusel.formats.small.url}
                        alt="Noticia Municipalidad de Rawson Chubut"
                      />
                    }     
                  </Link>
                  <Carousel.Caption>
                    <h4 className='titularCarousel'>
                      <strong>
                        {this.props.noticiasCarrouselData[3].Titular}
                      </strong>
                    </h4>
                  </Carousel.Caption>
                </Carousel.Item>
          
          </Carousel>
        </div>
     
      </>  
    )
  }  
}