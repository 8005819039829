import React, { Component } from 'react';
import NoticiaDesarrollo from '../components/NoticiaDesarrollo';
import {animateScroll as scroll  } from 'react-scroll';

class Noticias extends Component {

    componentDidMount(){scroll.scrollToTop();}

    url=window.location.href;
    render() {   
        return (
            <div>
                <NoticiaDesarrollo idNoticia={this.url.charAt(this.url.length-1)} dataCarrusel={this.props.location.state}/>
            </div>
        )
    }
}

export default Noticias;