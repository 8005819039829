import React, { Component } from 'react';
import gifTarmites from '../../images/tramites.webp';
import {Card, Button, Accordion } from 'react-bootstrap';
import '../styles/Home.css';


class GuiaTramites extends Component {
    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTarmites}
                                width="30%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                        
                                <h4 style={{marginTop:"3rem"}}>Documentacion Licitación Pública N° 02/21</h4>
                            
                        </div>
                    </div>
                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h4>Extracto</h4>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <ul>
                                            <li>
                                              <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/licitaciones/02_21/extracto_0221.jpg">
                                                  Descargar Extracto Licitación Pública N° 02/21
                                              </a>
                                            </li>
                                        </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h4>Descargar Documentación</h4>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <ul>
                                            <li>
                                              <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/licitaciones/02_21/LICITACION_0221_AH132645.pdf">
                                                Descargar Documentacón Licitación Pública N° 02/21
                                              </a>
                                            </li>
                                            <li>
                                              <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/licitaciones/02_21/CIRCULAR_01.pdf">
                                                Descargar Circular N° 01
                                              </a>
                                            </li>
                                        </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>

          
                             
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </React.Fragment>  
            
        )
    }
}

export default GuiaTramites;