import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';

export default class TelefonosUtiles extends React.Component {
  render() {
    return (
      <div>
      <link
        rel="stylesheet"
        href="https://blueowlcreative.com/wp/fortuna/wp-content/themes/fortuna/style.css?ver=4.4.21"
      />
       
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
          integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://blueowlcreative.com/wp/fortuna/wp-content/themes/fortuna/stylesheets/icons.css?ver=4.4.21"
        ></link>
        <link
          rel="stylesheet"
          href="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/css%2Ficomoon.woff?alt=media&token=1aea24b5-a7e7-4e3b-8dcd-7f62749e1168"
        />
        <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <link
          rel="stylesheet"
          href="https://www.bahia.gob.ar/wp-content/themes/fortuna/stylesheets/grid.css?ver=4.5.3"
        />
        {/* <link rel="stylesheet" href="https://www.bahia.gob.ar/wp-content/themes/fortuna/stylesheets/grid_responsive.css?ver=4.5.3"/> */}
        <link
          rel="stylesheet"
          href="https://www.bahia.gob.ar/wp-content/themes/fortuna/stylesheets/icons.css?ver=4.5.3"
        />

        
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <div className="content_body" style={{ marginTop: "60px" }}>
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="col-sm-8">
                <div
                  className="wpb_column vc_column_container vc_col-sm-8"
                  bis_skin_checked="1"
                >
                  <div className="vc_column-inner container-fluid" bis_skin_checked="1">
                    <div className="wpb_wrapper" bis_skin_checked="1">
                      <div
                        className="boc_spacing "
                        style={{ height: "20px" }}
                        bis_skin_checked="1"
                      ></div>
                      <h2
                        className="container-fluid boc_heading  al_left  boc_animate_when_almost_visible boc_fade-in boc_start_animation"
                        style={{
                          marginBottom: "20px",
                          marginTop: "0px",
                          color: "#333333",
                        }}
                      >
                        <span>
                          Centro de{" "}
                          
                            <strong>Atención al Vecino</strong>
                          
                        </span>
                      </h2>
                      <div
                        className="side_icon_box xlarge  boc_animate_when_almost_visible boc_start_animation"
                        bis_skin_checked="1"
                      >
                        <span
                          className="icon_feat  icon_solid"
                          style={{
                            background: "rgb(149, 200, 64)",
                            transitionDelay: "200ms",
                          }}
                        >
                          <div className="icon_bgr">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Ftelefono-lineal.svg?alt=media&token=5524472c-d235-466f-b1ff-20c4dbd1a94e"
                              alt=""
                              srcSet=""
                              style={{
                                color: "#fff",
                                width: "45px",
                                marginTop: "7px",
                                marginiRght: "7px",
                              }}
                            />
                          </div>
                        </span>
                       
                        <div
                          className="side_icon_box_content"
                          bis_skin_checked="1"
                          style={{ transitionDelay: "500ms" }}
                        >
                          <p></p>
                          <ul>
                            <li>
                              <Link to={`/guia_tramites`}>
                                Guía de Trámites.
                              </Link>
                            </li>
                          </ul>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="wpb_column vc_column_container vc_col-sm-4"
                  bis_skin_checked="1"
                >
                  <div className="vc_column-inner " bis_skin_checked="1">
                    <div className="wpb_wrapper" bis_skin_checked="1">
                      <div
                        className="boc_spacing "
                        style={{ height: "20px" }}
                        bis_skin_checked="1"
                      ></div>
                      <h2
                        className="boc_heading  al_left  boc_animate_when_almost_visible boc_fade-in boc_start_animation"
                        style={{
                          marginBottom: "20px",
                          marginTop: "0px",
                          color: "#333",
                        }}
                      >
                        <span>
                          Contacto <strong>Municipio</strong>
                        </span>
                      </h2>
                      <div
                        className="boc_list_item  "
                        style={{ marginBottom: "15px" }}
                        bis_skin_checked="1"
                      >
                        <span className="li_icon small ">
                          <div className="icon_bgr">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Ftelefono-lineal%20(1).svg?alt=media&token=82565dc3-2935-4aee-bea3-13cd9b55fd5f"
                              alt=""
                              srcSet=""
                              style={{
                                color: "#fff",
                                width: "45px",
                                marginTop: "7px",
                                marginiRght: "7px",
                              }}
                            />
                          </div>
                        </span>
                        <div
                          className="boc_list_item_text small"
                          bis_skin_checked="1"
                        >
                          +54 (0280) 448-1990
                        </div>
                      </div>
                      <div className="boc_list_item  " bis_skin_checked="1">
                        <span className="li_icon small ">
                          <div className="icon_bgr">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Fmarcador-ubicacion-2.svg?alt=media&token=b0b61421-7aa8-4bf4-bc7b-1a9dfabefcee"
                              alt=""
                              srcSet=""
                              style={{
                                color: "#fff",
                                width: "45px",
                                marginTop: "7px",
                                marginiRght: "7px",
                              }}
                            />
                          </div>
                        </span>
                        <div
                          className="boc_list_item_text small"
                          bis_skin_checked="1"
                        >
                          Mariano Moreno 650, Rawson
                          <br /> Provincia del Chubut, Argentina
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" style={{ marginTop: "100px" }}>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="boc_divider_holder" bis_skin_checked="1">
                <div
                  className="boc_divider  "
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: "1px",
                    background: "#eeeeee",
                  }}
                  bis_skin_checked="1"
                ></div>
              </div>
              <h2
                className="boc_heading  center  "
                style={{
                  marginBottom: "20px",
                  marginTop: "0px",
                  color: "#333333",
                }}
              >
                <span>
                  Teléfonos <strong>y Direcciones</strong>
                </span>
              </h2>
              <div className="boc_divider_holder" bis_skin_checked="1">
                <div
                  className="boc_divider  "
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: "1px",
                    background: "#eeeeee",
                  }}
                  bis_skin_checked="1"
                ></div>
              </div>
            </div>
          </div>

          <div
            data-vc-full-width="true"
            data-vc-full-width-init="true"
            className="vc_row wpb_row vc_row-fluid vc_custom_1427027142273 vc_row-has-fill"
            style={{
              marginTop: "60px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="row" style={{}}>
              <div className="col-sm-2">
                
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946565820">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_21258"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: "200ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Ftelefono-emergencias-medicas-lineal.svg?alt=media&token=275c9564-1da6-4b20-8fe4-10c945136f2e"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Emergencias
                          </p>
                          <h3>(0280) 449-6003</h3>
                        </div>
                      </div>
                    </div>
                  </div>
              
              </div>
              <div className="col-sm-2">
               
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946572475">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_26663"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: " 700ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Ftelefono-emergencias-medicas-negativo.svg?alt=media&token=eb03eecf-a73d-4bfa-81ec-1a6403807b8a"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Urgencias
                          </p>
                          <h3>911</h3>
                        </div>
                      </div>
                    </div>
                  </div>
      
              </div>
              <div className="col-sm-2">
            
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946572475">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_26663"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: " 700ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Fgenero.svg?alt=media&token=81f41f46-1802-426e-a43a-2d001d976194"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Violencia de Genero
                          </p>
                          <h3>114</h3>
                        </div>
                      </div>
                    </div>
                  </div>
             
              </div>
              <div className="col-sm-2">
              
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946572475">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_26663"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: " 700ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Ftelefono-lineal.svg?alt=media&token=5524472c-d235-466f-b1ff-20c4dbd1a94e"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Conmutador Municipio
                          </p>
                          <h3>(0280) 448-1990</h3>
                        </div>
                      </div>
                    </div>
                  </div>
            
              </div>
              <div className="col-sm-2">
        
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946572475">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_26663"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: " 700ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Fviajero.svg?alt=media&token=048a6c8b-01f4-4524-bed8-ebd9f60b59c0"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Turismo
                          </p>
                          <h3>(0280) 449-6887</h3>
                        </div>
                      </div>
                    </div>
                  </div>
               
              </div>
              <div className="col-sm-2">
              
                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1426946584356">
                      <div className="wpb_wrapper">
                        <div
                          id="top_icon_box_58390"
                          className="top_icon_box type2  boc_animate_when_almost_visible boc_start_animation"
                        >
                          <div
                            className="icon_holder"
                            style={{ transitionDelay: "1200ms" }}
                          >
                            <div
                              className="icon_bgr"
                              style={{ background: "#95C840" }}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/rawson-web-page.appspot.com/o/static%2Fcomunicacion.svg?alt=media&token=33b032fa-ebfd-4dc7-826c-73302c66a699"
                                alt=""
                                srcSet=""
                                style={{
                                  color: "#fff",
                                  width: "60px",
                                  marginTop: "16px",
                                }}
                              />
                            </div>
                            <div className="icon_center"></div>
                          </div>
                          <p
                            style={{
                              transitionDelay: "500ms",
                              color: "#808184",
                              fontSize: "25px",
                              textDecoration: "none",
                            }}
                          >
                            Radio Municipal
                          </p>
                          <h3>(0280) 448-5609</h3>
                        </div>
                      </div>
                    </div>
                  </div>
            
              </div>
            </div>
          </div>

          <table className="table text-center">
            <thead className="thead-light">
              <tr>
                <th scope="col">Nombre del Organismo</th>
                <th scope="col">Dirección</th>
                <th scope="col">Teléfono</th>
                {/*<th scope="col">Cómo llegar (desde el Municipio)</th>*/}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bomberos</td>
                <td></td>
                <td>100</td>
                <td></td>
              </tr>
              <tr>
                <td>Defensa Civil Urgencias</td>
                <td></td>
                <td>103</td>
                <td></td>
              </tr>
              <tr>
                <td>Prefectura Urgencias</td>
                <td></td>
                <td>106</td>
                <td></td>
              </tr>
              <tr>
                <td>Comisaría Rawson</td>
                <td></td>
                <td>(0280) 448-1713</td>
                {/*<td><a href="https://www.google.com/maps/place/Comisaria+Distrito+de+Rawson/@-43.2996732,-65.1069474,17z/data=!4m21!1m15!4m14!1m6!1m2!1s0xbe0157204931f133:0x3fc6010730a66a6a!2sMunicipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut!2m2!1d-65.1050235!2d-43.2999234!1m6!1m2!1s0xbe015760b5a09bd1:0x9b0ec9215cf9a644!2sComisaria+Distrito+de+Rawson,+9103,+Rawson,+Chubut!2m2!1d-65.104535!2d-43.299423!3m4!1s0xbe015760b5a09bd1:0x9b0ec9215cf9a644!8m2!3d-43.299423!4d-65.104535">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Comisaría Playa Unión</td>
                <td>Av. Juan Manuel de Rosas, Playa Union</td>
                <td>(0280) 449-6354</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Comisaria,+Avenida+Juan+Manuel+de+Rosas,+Playa+Union,+Chubut/@-43.3056057,-65.1087576,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156694ca47193:0x32f9cfe32af6102f!2m2!1d-65.0418359!2d-43.3110325">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Hospital Santa Teresita</td>
                <td>Argentino Roca N°545 Rawson</td>
                <td>(0280) 448-1260</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Hospital+Sub+Zonal+Rawson,+Julio+Argentino+Roca+545,+Rawson,+Chubut/@-43.2982503,-65.108804,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe01572216a00b33:0x555662e3bcbc5e61!2m2!1d-65.1071937!2d-43.296743">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Emergencia Médica</td>
                <td>Playa Unión</td>
                <td>(0280) 449-6003</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/hospital+playa+union/@-43.3039477,-65.0932732,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015668c29bb257:0xd9cc71ea54c49738!2m2!1d-65.0448313!2d-43.3107243">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Conmutador Municipio</td>
                <td>Mariano Moreno 650 Rawson</td>
                <td>(0280) 448-1990</td>
                {/*<td></td>*/}
              </tr>
              <tr>
                <td>Ruca Cumelen</td>
                <td>Bernardo Vaccina N°251 Rawson</td>
                <td>(0280) 448-1636</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Bernardo+Vachina+251,+Rawson,+Chubut/@-43.3009714,-65.106174,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156df96e03a79:0xba5250d1bdd3c036!2m2!1d-65.1029083!2d-43.3024154">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Casas Tuteladas</td>
                <td>Chacho Peñaloza N°500 Rawson</td>
                <td>(0280) 448-5152</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Casas+Tuteladas,+Avenida+Chacho+Pe%C3%B1aloza,+Rawson,+Chubut/@-43.3001409,-65.1114635,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0157a2738cc775:0x24cba9750561187d!2m2!1d-65.088145!2d-43.295724">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Cooperativa Servicios Públicos</td>
                <td>Mariano Moreno N°249 Rawson</td>
                <td>(0280) 448-2999	</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Cooperativa+Servicios+P%C3%BAblicos+%E2%80%93+Rawson/@-43.2980949,-65.1046245,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe01571ecef92d3b:0xf7f3dd0301f912c6!2m2!1d-65.1008832!2d-43.2962665">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Camuzzi Gas del Sur</td>
                <td>Don Bosco N°237 Rawson</td>
                <td>(0280) 448-1621</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Camuzzi+Gas+Del+Sur,+Don+Bosco,+Rawson,+Chubut/@-43.3017464,-65.1075287,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015793517bfe7b:0x4e80e1eeb0f81476!2m2!1d-65.1053579!2d-43.3035793">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Concejo Deliberante</td>
                <td>Luis Costa N°173 Rawson</td>
                <td>(0280) 448-4544</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Concejo+Deliberante+de+Rawson,+Luis+Costa,+Rawson,+Chubut/@-43.3012606,-65.1072086,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156df71404613:0x3c71ee5aba950d62!2m2!1d-65.1045865!2d-43.3025926">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Cine Teatro</td>
                <td>Av. Jorge Galina s/n Rawson</td>
                <td>(0280) 448-5564</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Espacio+INCAA+Cine+Rawson+-+Chubut,+Rawson,+Chubut/@-43.2988117,-65.1072846,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015721fa7a07dd:0x34283a920b027716!2m2!1d-65.1056998!2d-43.2977">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Veterinaria y Abasto</td>
                <td>Av. Libertad y Moreno Rawson</td>
                <td>(0280) 448-3628</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Direccion+De+Veterinaria+Saneamiento+Y+Abasto,+Avenida+Libertad,+Rawson,+Chubut/@-43.3041082,-65.111569,16z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156d0ac162acd:0xf464fe0b776e6b86!2m2!1d-65.1104627!2d-43.3081793">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Dirección Gral. de Empleo</td>
                <td>Hipólito Yrigoyen N°99 Rawson</td>
                <td>(0280) 448-3837</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/OFICINA+De+Empleo+Minicipal+Rw.,+Hip%C3%B3lito+Yrigoyen+50,+Rawson,+Chubut/@-43.3003039,-65.1085357,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015720e741dbf1:0xdb07aeec1b34d984!2m2!1d-65.1076644!2d-43.3004996">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Sec. de Ambiente, Producción y Desarrollo Sustentable</td>
                <td>Alejandro Conesa N°46</td>
                <td>(0280) 448-2703</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Alejandro+Conesa+46,+Rawson,+Chubut/@-43.2997274,-65.1056165,18z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015720305fdcfb:0x39f3d87844181c11!2m2!1d-65.1040029!2d-43.2998">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Sec de Turismo, Cultura, Deporte y Comunicación</td>
                <td>Avenida Galina s/n</td>
                <td>(0280) 448-5877</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Espacio+INCAA+Cine+Rawson+-+Chubut,+Rawson,+Chubut/@-43.2988117,-65.1072793,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe015721fa7a07dd:0x34283a920b027716!2m2!1d-65.1056998!2d-43.2977">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Sec. de Desarrollo Social y Familia</td>
                <td>Catamarca N° 230, B° Gregorio Mayo</td>
                <td>(0280) 499-9485</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/-43.2982542,-65.1084022/@-43.2990585,-65.1077075,18z/data=!4m8!4m7!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m0">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Sec. de Planificación, Obras y Servicios Públicos</td>
                <td></td>
                <td>Interno 131 (A través conmutador Municipio)</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Departamentos+Oasis,+Rawson,+Chubut/@-43.3000914,-65.1077167,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe01572057b133e3:0xd04be398da3e4cb1!2m2!1d-65.1060218!2d-43.3002196">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Oficina de Rentas Playa (cobro)</td>
                <td>Av. Guillermo Rawson N°380</td>
                <td>(0280) 449-6535</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Direccion+General+De+Rentas,+Av.+Guillermo+Rawson+380,+Playa+Union,+Chubut/@-43.3101178,-65.073937,14z/data=!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe01566e627fd039:0x6556989a99125269!2m2!1d-65.0398045!2d-43.3130069">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Terminal De Ómnibus</td>
                <td>Gregorio Mayo N° 599-659</td>
                <td>(0280) 448-5107</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Terminal+De+Omnibus+Rawson,+Gregorio+Mayo,+Rawson,+Chubut/@-43.3017239,-65.1054756,16z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156e0c1517db1:0xcfd481436a6abe0c!2m2!1d-65.0968099!2d-43.3038521">cómo llegar</a></td>*/}
              </tr>
              <tr>
                <td>Dirección de Transito</td>
                <td>Gregorio Mayo N°599-659</td>
                <td>(0280) 448-5530	</td>
                {/*<td><a href="https://www.google.com/maps/dir/Municipalidad+de+Rawson,+Mariano+Moreno,+Rawson,+Chubut/Terminal+De+Omnibus+Rawson,+Gregorio+Mayo,+Rawson,+Chubut/@-43.3017239,-65.1054756,16z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0xbe0157204931f133:0x3fc6010730a66a6a!2m2!1d-65.1050235!2d-43.2999234!1m5!1m1!1s0xbe0156e0c1517db1:0xcfd481436a6abe0c!2m2!1d-65.0968099!2d-43.3038521">cómo llegar</a></td>*/}
              </tr>
             
            </tbody>
          </table>                  





 
        </div>
      </div>
    );
  }
}
