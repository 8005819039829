import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import '../../pages/styles/Home.css';
import '../../pages/styles/Rentas.css';
import { GrDownload } from "react-icons/gr";
import Autos from "../../images/transito/autos.gif";


class C3 extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container familiafade">    

                <div className="row justify-content-center text-center" style={{marginTop:'1rem'}}>
                        <div className="col"></div>
                        <div className="col-md-8">
                          <h5>Categorías de licencia de conducir, clases y subclases.</h5>
                        </div>
                      
                        <div className="col-md-2">
                          <img
                                      className="img-fluid mx-auto d-block"
                                      alt="Transito"
                                      src={Autos}
                                      width="90%"
                          />                        
                        </div>

                        <div className="col"></div>
                  </div>
    
                  <div className="row justify-content-center" style={{marginTop:50,borderTop:'solid',borderWidth:0.5}}>
                    
                    
                      <div className="d-inline p-1">
                        <b style={{fontSize:16}}>DESCARGAR DOCUMENTO</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/categoria%20de%20licencia%20de%20conducir%20clases%20y%20subclase.jpeg','_blank')}>
                          Descargar
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                    
                   
                  </div>
                
              </div>

            </React.Fragment>      
        )
    }
}

export default C3;