import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {Image} from 'react-bootstrap';
import Loader from './Loader';
import './styles/Noticias.css';


const options = "/noticias?_sort=published_at:DESC&_limit=4";
const url = "https://boletinoficial.rawson.gov.ar";

const NoticiaDesarrollo = (props) => {

    const [noticiasCarrusel, setNoticiasCarrusel] = useState([]);
    const [loadingImg, setLoadingImg] = useState(true);
    const [loading, setLoading] = useState(true);

    const getData = async()=>{
        let noticiasCarrusel = await axios.get(url+options);
        setNoticiasCarrusel(noticiasCarrusel.data);
        setLoading(false);
    }

    useEffect(() => {
        if(props.dataCarrusel===undefined){
            getData();
        }else{
            setNoticiasCarrusel(props.dataCarrusel.data);
            setLoading(false);
        }
    },[])
    
    const getMes = (mesNumero)=>{
        switch (mesNumero) {
            case 1:
                return('Enero');                
            case 2:
                return('Febrero');              
            case 3:
                return('Marzo');             
            case 4:
                return('Abril');           
            case 5:
                return('Mayo');             
            case 6:
                return('Junio');           
            case 7:
                return('Julio');
            case 8:
                return('Agosto');   
            case 9:
                return('Septiembre');    
            case 10:
                return('Octubre');        
            case 11:
                return('Noviembre');
            default:
                return('Diciembre');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-md-center" style={{marginTop:"0.4rem"}}>
                <div className="col"></div>

                <div className="col-sm-12 col-md-10 col-lg-8">
                    {(loading || loadingImg) &&
                        
                        <div className="col-md-12 text-center"><Loader/></div>                    
                    }
                    {!loading &&
                    
                        <div className="fadeHome" style={loadingImg?{display:'none'}:{}}>
                            <Image
                                className="w-100"
                                rounded='true'        
                                alt="Noticias"
                                onLoad={()=>{setLoadingImg(false)}}
                                src={url+noticiasCarrusel[props.idNoticia].Imagen_Desarrollo.formats.medium.url}
                            />
                        </div>
                    } 
                </div>
                <div className="col"></div>     
            </div>
            
            <div className="row styleTextDesarrollo" style={{padding:"1rem", borderTopStyle:"solid", borderWidth:"1px"}}>
                <div className="col"></div>
                
                <div className="col-md-10 col-lg-8">
                    {loading ?
                        <div dangerouslySetInnerHTML={{__html: "<h5>Cargando...</h5>"}} />
                        :
                        <div>
                            <p style={{fontSize:13}}>
                            {
                                new Date(noticiasCarrusel[props.idNoticia].published_at).getDate()+' de '+
                                (getMes(new Date(noticiasCarrusel[props.idNoticia].published_at).getMonth()+1))+' del '+
                                new Date(noticiasCarrusel[props.idNoticia].published_at).getFullYear()+' Municipalidad de Rawson Chubut.'
                            
                            }
                            </p>
                            <div dangerouslySetInnerHTML={{__html: noticiasCarrusel[props.idNoticia].Desarrollo_Noticia}} />   
                        </div>
                    }
                </div>   

                <div className="col"></div>
            </div>
        
        </div>
    )
}

export default NoticiaDesarrollo;