import React, { Component } from 'react';
import gifTramites from '../images/tramites.webp';
import lic_01_21 from '../images/licitaciones/01_21.webp';
import lic_04_21 from '../images/licitaciones/04_21.webp';
import optierras1 from '../images/licitaciones/optierras.webp';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/Home.css';


class GuiaTramites extends Component {
    render() {
        return (

            <React.Fragment>
                <div className="container-fluid">
                    <div className="row headerTramites">
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTramites}
                                width="20%"
                            />
                        </div>
                        <div className="col-md-6 text-center">

                            <h4 style={{ marginTop: "3rem" }}>Licitaciones Públicas y Ofrecimientos Públicos</h4>

                        </div>
                    </div>
                </div>

                <div className="container-fluid palacio">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-4 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className="BtncardInfo text-center">
                                        <h5>Ofrecimiento Público de Tierras</h5>
                                    </Card.Header>

                                    <Card.Body>

                                        <Card.Title className="contenedor">
                                            <Link to="/optierras1" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="OpTierras"
                                                    src={optierras1}
                                                />
                                            </Link>
                                        </Card.Title>

                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-md-4 cardPadding">

                                <Card className="cardAlto">
                                    <Card.Header className="BtncardInfo text-center">
                                        <h5>Licitación Pública N° 01/21</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title className="contenedor">
                                            <Link to="/0121" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={lic_01_21}
                                                />
                                            </Link>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>

                            </div>

                            <div className="col-md-4 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className="BtncardInfo text-center">
                                        <h5>Licitación Pública N° 04/21</h5>
                                    </Card.Header>

                                    <Card.Body>

                                        <Card.Title className="contenedor">
                                            <Link to="/0421" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={lic_04_21}
                                                />
                                            </Link>
                                        </Card.Title>

                                    </Card.Body>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GuiaTramites;