import React, { Component, Fragment } from 'react';
import { Image } from 'react-bootstrap';
import './styles/Noticias.css';
import {animateScroll as scroll  } from 'react-scroll';

const ReactMarkdown = require('react-markdown')
const url = "https://boletinoficial.rawson.gov.ar";

class Funcionario extends Component {

    constructor(props){
        super(props);  

        this.state={
            funcionario:props.location.state
        }
    }  

    componentDidMount(){scroll.scrollToTop();}
    
    render() {
        
        return (
            <Fragment>
                <div className="container-fluid">
                
                    <div className="row styleTextTitular">
                        
                        <div className="col-md-5">
                            <Image
                                className="img-fluid mx-auto d-block"
                                alt="Gabinete"
                                src={url+this.state.funcionario.Foto[0].url}    
                                width="70%"
                            />
                        </div>
                        <div className="col-md-7">
                            <h4 style={{'margin-top':'5rem'}}>
                                {this.state.funcionario.Titular}
                            </h4>
                        </div>  
                        
                    </div>

                    <hr></hr>

                    <div className="row" >
                        <div className="col-md-1"></div>
                        <div className="col-sm-10">
                          
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    
                    <div className="row styleTextDesarrollo">
                        <div className="col-md-1"></div>
                        <div className="col-md-9">
                            <ReactMarkdown source={this.state.funcionario.Bio} />
                        </div>   
                        <div className="col-md-2"></div>
                    </div>

                </div>
            </Fragment>
        
        )
    }
}

export default Funcionario;