import React from 'react';
import './styles/Badge.css'
import Navbar from './Navbar';
import Footer from './Footer';
//import TopHeader from '../components/top-header';
function Layout(props) {
  // const children = props.children;

  return (
    <React.Fragment>
      <Navbar />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
