import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {Image} from 'react-bootstrap';
import './styles/Badge.css'
import Escudo from '../images/escudoMuni.webp';
import Layout from './Layout';
import Home from '../pages/Home';
import Gabinete from '../pages/gabinete';
import Funcionario from '../components/Funcionario';
import Noticias from '../pages/Noticias';
import DesarrolloNotSec from '../components/DesarrolloNotSec';
import NotFound from '../pages/NotFound';
import Construccion from '../pages/construccion';
import Rentas from '../pages/Rentas';
import Produccion from '../pages/Produccion';
import Familia from '../pages/Familia';
import Boletin from '../components/boletinOficial/BoletinOficial';
import Telefonos from '../pages/telefonos-utiles'
import GuiaTramites from '../pages/GuiaTramites';
import ServicioEmpleado from '../pages/ServicioEmpleado';
//import FamiliaTramites from '../pages/desarrolloGuiaTramites/familia/familiaTramites';
import TransitoTramites from '../pages/desarrolloGuiaTramites/transito/transitoTramites';
import Transito from '../pages/Transito';
import Personal from '../pages/personal/personal';

import LoginIiBbProcess from '../pages/LoginIiBbProcess';

import Op from '../pages/desarrolloGuiaTramites/op/op';
import TelefonosUtiles from '../pages/telefonos-utiles';
import ProgramaLotear from '../pages/ProgramaLotear';
import LoteoSocial from '../pages/desarrolloLotear/loteoSocial/loteoSocial';
import Requisitos from '../pages/desarrolloLotear/requisitosAmbas/requisitos';
import PrimeraVivienda from '../pages/desarrolloLotear/primeraVivienda/primeraVivienda';
import LotesDeInversion from '../pages/desarrolloLotear/lotesDeInversion/lotesDeInversion';
import Licitaciones from '../pages/Licitaciones'
import Lic0121 from '../pages/desarrolloLicitaciones/01_21'
import Lic0221 from '../pages/desarrolloLicitaciones/02_21'
import Lic0421 from '../pages/desarrolloLicitaciones/04_21'
import optierras1 from '../pages/desarrolloLicitaciones/optierras1'
//import Obras from '../pages/Obras'
//import Encuesta from '../pages/Encuesta'

function App() {
 
  const [loading, setLoading] = useState(true);
  const [dataHome, setDataHome] = useState({});
  const [noticiasCarrusel, setNoticiasCarrusel] = useState([]);
  const [noticiasSecundarias,setNoticiasSecundarias] = useState([]);
  const apiUrl = 'https://boletinoficial.rawson.gov.ar';
  const options = "/noticias?_sort=published_at:DESC&_limit=4";

  useEffect(() => {getInfo()},[]);

  const getInfo=(async()=>{
    var noticiasCarruselRes = await axios.get(apiUrl+options);
    var responseHome = await axios.get(apiUrl+'/home');
    var responseNoticiasSec = await axios.get(apiUrl+'/noticias-secs');
    let noticiasSecundarias_buff = [];

    await responseNoticiasSec.data.forEach((noticia_sec) => {
      if(noticia_sec.visibleEnWeb){
        noticiasSecundarias_buff.push(noticia_sec)
        noticiasSecundarias_buff.sort((a,b)=>{
          if (a.orden < b.orden) {return -1;}
          if (a.orden > b.orden) {return 1;}
          return 0;
        })
      } 
    });

    setNoticiasCarrusel(noticiasCarruselRes);
    setDataHome(responseHome);
    setNoticiasSecundarias(noticiasSecundarias_buff);
    setLoading(false);
   
  })

  
  if(loading){
    return(
      <div className='container-fluid'>
        <div className='row' style={{marginTop:'11%'}}>
          <div className='col'></div>
          <div className='text-center col-md-4 col-lg-4'>
            <Image
              className="img-fluid fadeHome shadow"
              rounded='true'
              roundedCircle='true'
              alt="Municipalidad de Rawson Chubut"
              src={Escudo}
            />
          </div>
          <div className='col'></div>
        </div>
      </div>
    )
  }else{
    return (
      <BrowserRouter>
        <Layout>
          <Switch>

            <Route exact path="/">
              <Home 
                dataHome={dataHome} 
                noticiasCarrusel={noticiasCarrusel}
                noticiasSecundarias={noticiasSecundarias}
              />
            </Route>

            <Route exact path="/noticias/:idNoticia" component={Noticias} />
            <Route exact path="/gabinete" component={Gabinete} />
            <Route exact path="/funcionario" component={Funcionario} />
            <Route exact path="/guia_tramites" component={GuiaTramites} />
            <Route exact path="/servicio_empleado" component={ServicioEmpleado} />
            <Route exact path="/personal" component={Personal} />

            <Route exact path="/login-iibb-process" component={LoginIiBbProcess} />
            
            {/*<Route exact path="/encuesta_se" component={Encuesta} />*/}

            <Route exact path="/tramites_familia" component={Familia} />
            
            <Route exact path="/tramites_transito" component={TransitoTramites} />
            <Route exact path="/transito" component={Transito} />

            <Route exact path="/tramites_op" component={Op} />

            <Route exact path="/lotear" component={ProgramaLotear} />
            <Route exact path="/loteoSocial" component={LoteoSocial} />
            <Route exact path="/lineaIntermedia" component={PrimeraVivienda} />
            <Route exact path="/lotesDeInversion" component={LotesDeInversion} />
            <Route exact path="/requisitos" component={Requisitos} />

            <Route exact path="/rentas" component={Rentas} />
            <Route exact path="/produccion" component={Produccion} />
       

            <Route exact path="/noticias_sec" component={DesarrolloNotSec} />
            <Route exact path="/impuestos" component={Construccion} />
            <Route exact path="/boletin" component={Boletin} />
            <Route exact path="/telefono" component={TelefonosUtiles} />
            <Route exact path="/construccion" component={Construccion} />
            <Route exact path="/telefonos" component={Telefonos} />

            <Route exact path="/licitaciones" component={Licitaciones}/>
            {/*<Route exact path="/obras" component={Obras}/>*/}

            <Route exact path="/0121" component={Lic0121}/>
            <Route exact path="/0221" component={Lic0221}/>
            <Route exact path="/0421" component={Lic0421}/>
            
            <Route exact path="/optierras1" component={optierras1}/>

            {/*<Route exact path="/example/example" component={example} />*/}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
  
}

export default App;
