import React, { Component } from 'react';
import '../../pages/styles/Home.css'
import TsocialImg from "../../images/familia/tsocial.gif" 
import '../../pages/styles/Rentas.css';

class Tsocial extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container familiafade">    

                <div className="row justify-content-center" style={{marginTop:20}}>
                      <div className="col"></div>
                      <div className="col-md-8 text-center">
                        <h4>Requisitos Para acceder a la Tarjeta Social</h4>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                          className="img-fluid mx-auto d-block"
                          alt="Ingresos Brutos"
                          src={TsocialImg}
                          width="60%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>

                <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba',padding:'1rem'}}>
                  <div className="col">
                    <ul class="list-group">
                      <li class="list-group-item"><b>1</b>-NOTA DE SOLICITUD.</li>
                      <li class="list-group-item"><b>2</b>-FOTOCOPIA DE DNI DEL TITULAR Y GRUPO FAMILIAR CONVIVIENTE, EN CASO DE NO POSEER DNI PRESENTAR EL ACTA DE NACIMIENTO.</li>
                      <li class="list-group-item"><b>3</b>-ADP( ACREDITACIÓN DE DATOS PERSONALES ) Y CERTIFICACIÓN NEGATIVA DE ANSES (MAYORES DE 18 )</li>
                      <li class="list-group-item"><b>4</b>-CERTIFICADO DE SALUD O DISCAPACIDAD.</li>
                      <li class="list-group-item"><b>5</b>-ÚLTIMO RECIBO DE HABERES O DECLARACIÓN JURADA DE INGRESOS EMITIDA POR EL JUZGADO DE PAZ (MAYORES DE 18).</li>
                      <li class="list-group-item"><b>6</b>-CONSTANCIA DE TITULARIDAD DE TERRENO, RECIBO DE ALQUILER O CONTRATO</li>
                      <li class="list-group-item"><b>7</b>-FOTOCOPIA DE SERVICIO.</li>
                    </ul>
                  </div>
              
                </div>
              </div>

            </React.Fragment>      
        )
    }
}

export default Tsocial;