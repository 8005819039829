import React from 'react';
import {Card, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loadFuncionario from '../images/loadImage.webp';

import './styles/Home.css'

import axios from 'axios';
const url = "https://boletinoficial.rawson.gov.ar";

class Gabinete extends React.Component {
  
  constructor(props){
    super(props);
    this.state={
      funcionarios:[],
      loading:true
    }
  }  

  async componentDidMount(){
    var funcionariosBuff = {};
    funcionariosBuff = await axios.get(url+'/funcionarios');  
    this.setState({
      funcionarios:funcionariosBuff.data,
      loading:false
    });

  }

  render() {
    
    return (
      <React.Fragment> 
      <div className="container-fluid">
        <div className="row"></div>
      </div>    
             
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4" align="center"></div>  
          <div className="col-md-4 cardPadding" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[0]}}>{/*Whoooo*/}
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[0].Foto[0].formats.small.url}
                      />
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[0].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4" align="center"></div>
        </div>
        <div className="row">
            
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[4]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[4].Foto[0].formats.small.url}
                      />
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[4].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[3]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[3].Foto[0].formats.small.url}
                      /> 
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[3].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[1]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[1].Foto[0].formats.small.url}
                      /> 
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[1].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[5]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[5].Foto[0].formats.small.url}
                      /> 
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[5].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[2]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[2].Foto[0].formats.small.url}
                      /> 
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[2].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>
            <div className="col-md-2" align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <Link to={{pathname:'/funcionario',state:this.state.funcionarios[6]}}>
                    {
                      this.state.loading
                      ?
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={loadFuncionario}
                      />
                      :
                      <Image
                        className="img-fluid"
                        alt="Gabinete"
                        src={url+this.state.funcionarios[6].Foto[0].formats.small.url}
                      /> 
                    }
                  </Link>  
                </Card.Title>
                <Card.Text className="titularNombres">
                  {this.state.loading ? 'Cargando..' : this.state.funcionarios[6].Titular}
                </Card.Text>            
              </Card.Body>
            </Card>

            </div>

        </div>
      </div>  
      </React.Fragment>
    );
  }
}

export default Gabinete;
