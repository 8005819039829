import React, { Component } from 'react'
import {Button,Modal,Form} from 'react-bootstrap';
import axios from 'axios';
import '../Noticias.css';
const url = "https://boletinoficial.rawson.gov.ar/lotears/611fc70805dc87000e84071f";


class LoteoSocial extends Component {
    
    constructor (props) {
      super(props)
      this.state = {
        infoLoteoSocial:'',       
      }   
    } 

    async componentDidMount(){
      var infoLoteoSocial= await axios.get(url);
      this.setState({
        infoLoteoSocial:infoLoteoSocial.data.Descripcion,
      })
    } 

    preInscripcion=()=>{
      this.setState({
          show:true,
      })
  }

  handleClose=e=>{
      this.setState({
          show:false
      })
  }

    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Loteo Social"
                                src={'-'}
                                width="30%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                          <h4 style={{marginTop:"3rem"}}>Información Loteo Social</h4> 
                        </div>
                    </div>
                    <div className="row styleTextDesarrollo" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">


                          <div dangerouslySetInnerHTML={{__html: this.state.infoLoteoSocial}} />   
                
                             
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                    
                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 text-center">

                        <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.preInscripcion}>
                          Formulario de Pre Inscripción
                        </Button>
                        
                          <Modal show={this.state.show} onHide={this.handleClose}>
                    
                            <Modal.Header closeButton>
                                <Modal.Title>ATENCIÓN:</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                                <Form.Group controlId="formBasicCheckbox">
                                    <p>
                                        Ud. esta por completar el formulario de preinscripción que tiene calidad de <strong>Declaración Jurada. </strong> 
                                        Lo aquí declarado, llegado el momento, deberá ser acreditado correctamente. 
                                        Por último,  Realizar este trámite <strong>no significa</strong> que Ud, ya es beneficiario de un terreno.
                                    </p>
                                </Form.Group>

                            </Modal.Body>

                            <Modal.Footer>
                                <a href={"https://docs.google.com/forms/d/e/1FAIpQLScaloYzmBa41AVH8qQPcVi2cGC5G1CNHwfcpk65FXpn6f5wvA/viewform?usp=sf_link"}>
                                    <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.handleClose}>    
                                        ACEPTO
                                    </Button>
                                </a>
                                <a href={"https://rawson.gov.ar"}>
                                <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.handleClose}>
                                    RECHAZO
                                </Button>
                                </a>
                            </Modal.Footer> 

                        </Modal>    


                      </div>
                      <div className="col-md-2"></div>
                      
                    </div>
                    
                </div>
            </React.Fragment>  
            
        )
    }
}

export default LoteoSocial;