import React, { Component } from 'react';
import {Image,Carousel,Spinner} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';

class Arbolado extends Component {

    constructor(props){
      super(props);
      this.state={
        loading:true,
      } 
    }  
    render() {
        return (
            <React.Fragment> 
        
            <div>    

            <div className="row justify-content-center test">


                  <div className="col"></div>
                  

                  <div className="col-sm-12 col-md-10 col-lg-8 text-center">
                    {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}
                    <div style={this.state.loading ? {display: 'none'} : {}}>
                      <Carousel className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>

                        <Carousel.Item>
                          
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_1_fichas_arboles_7bb28294af.jpg?8097458', '_blank')}
                            onLoad={()=>this.setState({loading:false})}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_1_fichas_arboles_7bb28294af.jpg?8097458"
                            alt="Arbolado Publico"
                          />
                          <Carousel.Caption>
                        
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_6_ARBOL_ESP_VERDE_9e0f68b39d.jpg?8128178.5', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_6_ARBOL_ESP_VERDE_9e0f68b39d.jpg?8128178.5"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_3_ARVOL_VEREDA_ANOSTA_a2a5725a5e.jpg?8217458.1', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_3_ARVOL_VEREDA_ANOSTA_a2a5725a5e.jpg?8217458.1"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_4_A_Rb_OL_VEREDA_MEDIA_copia_6801197a44.jpg?8305274.200000001', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_4_A_Rb_OL_VEREDA_MEDIA_copia_6801197a44.jpg?8305274.200000001"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_2_ARBOLES_MEDIDAS_4884269085.jpg?8333663.5', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_2_ARBOLES_MEDIDAS_4884269085.jpg?8333663.5"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                          <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_7_arbustivas_copia_1b38ab099a.jpg?8360265.9', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_7_arbustivas_copia_1b38ab099a.jpg?8360265.9"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_5_ARBOL_VEREDA_ANCHA_bf0912c8fb.jpg?8419306.200000001', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_5_ARBOL_VEREDA_ANCHA_bf0912c8fb.jpg?8419306.200000001"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_5_ARBOL_VEREDA_ANCHA_bf0912c8fb.jpg?8419306.200000001', '_blank')}
                            className="d-block w-100"
                            src="https://boletinoficial.rawson.gov.ar/uploads/medium_5_ARBOL_VEREDA_ANCHA_bf0912c8fb.jpg?8419306.200000001"
                            alt="Arbolado Publico"
                          />

                          <Carousel.Caption>
                          
                          </Carousel.Caption>
                        </Carousel.Item>

                      </Carousel>
                    </div>

                  </div>

                  <div className="col"> </div>


                
                  </div>

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default Arbolado;