import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import { FcDownload } from "react-icons/fc";
import Auto from "../../images/rentas/auto.gif" 
import '../../pages/styles/Rentas.css';

class Automotores extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container test">    


                <div className="row justify-content-start" style={{marginTop:20}}>
                      <div className="col"></div>
                      <div className="col-md-8">
                        <h4>Aquí puedes descargar documentación e información del Impuesto Automotor</h4>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Ingresos Brutos"
                                    src={Auto}
                                    width="90%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>

                  <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba'}}>


                  <div className="col-md-6 text-center">

                    <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                        <div className="col"></div>
                      
                        <div className="col-md-6">
                          <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Inscripción impuesto automotor 0 kilómetro</b>
                        </div>
                        <div className="col-md-3">
                          <Button 
                            style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/inscripcion_0_km.pdf','_blank')}>
                            <FcDownload size='1.5em'/>
                          </Button>
                        </div>
                      
                        <div className="col"></div>
                    </div>

                    <div className="row align-items-center" style={{marginTop:5,padding:5}}>

                        <div className="col"></div>
                      
                        <div className="col-md-6">
                          <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Inscripción impuesto automotor usados</b>
                        </div>
                        <div className="col-md-3">
                        <Button 
                            style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/inscripcion usados.pdf','_blank')}>
                            <FcDownload size='1.5em'/>
                          </Button>
                        </div>
                      
                        <div className="col"></div>
                    </div>

                    <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                      <div className="col"></div>
                      
                      <div className="col-md-6">
                        <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Solicitud de Libre de Deuda</b>
                      </div>
                      <div className="col-md-3">
                      <Button 
                            style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/solicitud_libre_deuda.pdf','_blank')}>
                            <FcDownload size='1.5em'/>
                          </Button>
                      </div>
                    
                      <div className="col"></div>
                    </div>

                

                
        
                  </div>

                  







                  <div className="col-md-6 text-center">

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                    <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Como generar tu propio código LINK</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/generar_link.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>  

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Solicitud de Baja por cambio de Radicación</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/solicitud_baja.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:15,fontFamily:'Times New Roman'}}>Pago del Impuesto Automotor</b>
                    </div>
                    <div className="col-md-3">
                    <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/automotores/pago_impuesto.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>

                 

                

               
                 
                  </div>
                
                  </div>

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default Automotores;