import React, { Component } from 'react';
import gifTramites from '../images/tramites.webp';

import sueldos from '../images/tramites/sueldos.webp';
import personal from '../images/tramites/personal.webp';    
import {animateScroll as scroll  } from 'react-scroll';

import {Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/Home.css';


      

class ServicioEmpleado extends Component {

    componentDidMount(){
        scroll.scrollToTop();   
    }  

    render() {
        return (
        
            <React.Fragment> 
                <div className="container-fluid"> 
                    <div className="row headerTramites">
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTramites}
                                width="15%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                            <h4 style={{marginTop:"3rem"}}>Sueldos y Personal</h4>
                        </div>
                    </div>  
                </div>
                


                <div className="container-fluid palacio">
                    
                    <div className="col-lg-12"> 

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-md-4 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Dirección de Sueldos</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <a target="_blank" rel="noopener noreferrer" href={"https://tramites.rawson.gov.ar/Sueldos/index_sue.html"}>
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={sueldos}
                                                />          
                                            </a>                  
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>
                            <div className="col-md-4 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Dirección de Personal</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <Link to="/personal" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={personal}
                                                />          
                                            </Link>                        
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>   
                            <div className="col"></div>     

                        </div>
                    </div>
                </div>


            </React.Fragment>  
            
        )
    }
}

export default ServicioEmpleado;