import React, { Component } from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import '../../pages/styles/Home.css';
import ComercioGif from "../../images/rentas/comercio.gif"
import { FcDownload, FcCalendar, FcStart } from "react-icons/fc";
import { FaEye } from "react-icons/fa";
import '../../pages/styles/Rentas.css';

class Comercio extends Component {
    constructor(props){
      super(props);
      this.state={
          show:false,
          msjLi:false,
          videoModal:false,
          titleModal:'p1',
          explModal:'adjhdshjdsbjhdsab'
      }
    }

    detalle=(titulo,explicacion)=>{
      if(titulo==='Instructivo'){
        this.setState({
          titleModal:titulo,
          videoModal:true,
          show:true
      })
      }else{
        this.setState({
            titleModal:titulo,
            explModal:explicacion,
            videoModal:false,
            show:true
        })
      }
    }

    detalleClose=e=>{
        this.setState({
            show:false
        })
    }

    render() {
        return (
            <React.Fragment> 
        
              <div className="container test">    
                  <div className="row justify-content-start" style={{marginTop:20}}>
                      <div className="col"></div>
                      <div className="col-md-8">
                        <h4>Aquí puedes descargar la documentación e información de La Dirección de Comercio</h4>
                      </div>
                    
                      <div className="col-md-2">
                        <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Ingresos Brutos"
                                    src={ComercioGif}
                                    width="90%"
                        />                        
                      </div>

                      <div className="col"></div>
                </div>


                  <div className="row justify-content-center" style={{borderStyle:'solid',borderWidth:2,borderColor:'#58a5ba',marginBottom:20}}>


                  <div className="col-md-6 text-center">

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                      <div className="col"></div>
                    
                      <div className="col-md-6">
                        <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Requisitos 2023</b>
                      </div>

                      <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block" 
                          onClick={()=>this.detalle('Requisitos 2023 - Detalle', 'En este documento encontrará todos los requisitos actualizados al año 2023')}>
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>

                      <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/REQUISITOS%202021.doc.pdf','_blank')}>  
                        <FcDownload size='1.5em'/></Button>
                      </div>
                    
                      <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>

                      <div className="col"></div>
                    
                      <div className="col-md-6">
                        <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Autorización de Uso</b>
                      </div>
                      <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Autorización de Uso - Detalle', 'Cuando no se realiza contrato de alquiler, no se posee titulo de propiedad del inmueble, se puede realizar una autorización de uso entre el propietario y quien va a hacer uso del inmueble.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/AUTORIZACION%20DE%20USO.doc.pdf','_blank')}>  
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                    
                      <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                    <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario Secretaría de Planificación</b>
                    </div>
                    <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Formulario Secretaría de Planificación - Detalle', 'Es el primer trámite a iniciar, el mismo es un informe de estado del inmueble respecto a planos, hechos existentes y condiciones edilicias, si es apto para desarrollar la actividad.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20PLANIFICACION.doc.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                  
                    <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de Cooperativa de Rawson</b>
                    </div>
                    <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Formulario de Cooperativa de Rawson - Detalle', 'Se completa y presenta en la entidad para que la misma informe condiciones del inmueble respecto al servicio eléctrico.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                    </div>
                    <div className="col-md-2">
                      <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                        className="btn-block"
                        onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20COOPERATIVA.doc.pdf','_blank')}>  
                        <FcDownload size='1.5em'/>
                      </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de Asesoramiento</b>
                    </div>
                    <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Formulario de Asesoramiento - Detalle', 'Se completa y presenta para solicitar una inspección y así evaluar si el inmueble donde se desarrollará la actividad comercial cumple con las condiciones, si se debe hacer algún acondicionamiento/reforma.')}>
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20ASESORAMIENTO.doc.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                  
                    <div className="col"></div>
                  </div>
        
                  </div>

                  







                  <div className="col-md-6 text-center">

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario Bomberos</b>
                    </div>
                    <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                        className="btn-block"
                        onClick={()=>this.detalle('Formulario Bomberos - Detalle', 'Se debe completar el formulario y presentar en la institución, la misma informará el estado del inmueble respecto a las medidas de seguridad.')}>
                        <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                    </div>
                    <div className="col-md-2">
                      <Button 
                        style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                        className="btn-block"
                        onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20BOMBEROS.doc.pdf','_blank')}>  
                        <FcDownload size='1.5em'/>
                      </Button>
                    </div>
                  
                    <div className="col"></div>
                  </div>  

                  

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de Solicitud de Habilitación</b>
                    </div>
                    <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Formulario de Solicitud de Habilitación - Detalle', 'El mismo tiene carácter de declaración jurada por lo cual no debe presentar tachaduras/correcciones de ningún tipo.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20SOLICITUD%20HABILITACION.doc.pdf','_blank')}>  
                          <FcDownload size='1.5em'/>
                        </Button>
                      </div>
                  
                    <div className="col"></div>    
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Formulario de rentas (Impuesto Inmobiliario)</b>
                    </div>
                    <div className="col-md-2">
                      <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Formulario de rentas (Impuesto Inmobiliario) - Detalle', 'Se completa y presenta con el resto de la documentación en la oficina de la Dirección Gral de Comercio.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                      </Button>
                    </div>
                    <div className="col-md-2">
                        <Button 
                          style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Comercio/FORMULARIO%20RENTAS-IMPUESTO%20INMOBILIARIO.doc.pdf','_blank')}>
                          <FcDownload size='1.5em'/>
                        </Button>
                    </div>
                  
                    <div className="col"></div>    
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5,backgroundColor:'#bce5f0'}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Turno Online</b>
                    </div>
                    <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Turno Online - Detalle', 'Una vez que tenga la documentación completa podrá sacar un turno online para concurrir a la Direccón Genereal de Comercio.')}>  
                          <FaEye color='#1b7eb0' size='1.5em'/>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}}
                          className="btn-block"
                          onClick={()=>window.open('https://turnos-municipio.rawson.gov.ar','_blank')}>
                          <FcCalendar size='1.5em'/>
                        </Button>
                      </div>
                  
                    <div className="col"></div>   
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,padding:5}}>
                  <div className="col"></div>
                    
                    <div className="col-md-6">
                      <b style={{fontSize:16,fontFamily:'Times New Roman'}}>Video Explicativo</b>
                    </div>
                   
                      <div className="col-md-2">

                        <Button style={{borderRadius:50,backgroundColor:'#58ba9c'}} 
                          className="btn-block"
                          onClick={()=>this.detalle('Instructivo', '-')}>  
                          <FcStart color='#1b7eb0' size='1.5em'/>
                        </Button>

                      </div>
                      <div className="col-md-2"></div>
                  
                    <div className="col"></div>   
                  </div>
                 
                  </div>
                
                  </div>

              

                  <Modal show={this.state.show} onHide={this.detalleClose}>
                
                      <Modal.Header closeButton>
                          <Modal.Title>{this.state.titleModal}</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>

                          {this.state.videoModal 
                          ?
                            <Form.Group controlId="formBasicCheckbox">
                              <ReactPlayer url="https://www.youtube.com/watch?v=ifu_8uWcBAw" width="100%" height="20rem" controls muted/>  
                            </Form.Group>
                          :
                            <Form.Group controlId="formBasicCheckbox">
                                <p>
                                    {this.state.explModal}
                                </p>
                            </Form.Group>
                          }

                      </Modal.Body>

                      <Modal.Footer>
                          
                        <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.detalleClose}>    
                            CERRAR
                        </Button>
                      
                      </Modal.Footer> 

                  </Modal>    

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default Comercio;