import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy'



class VideoRawsonTurismo extends Component {

    render () {
        return <ReactPlayer 
                    url={this.props.urlVideo} 
                    width={this.props.ancho} 
                    height={this.props.alto} 
                    controls muted
                />
    }
}

export default VideoRawsonTurismo;