import React, { Component } from 'react'
//import logoLotear from '../images/lotear/logoLotear.png';
import logoLotear from '../../../images/lotear/logoLotear.png';
import {Button,Modal,Form} from 'react-bootstrap';
import axios from 'axios';
import '../Noticias.css';
import { Link } from 'react-router-dom';
import {animateScroll as scroll  } from 'react-scroll';



const urlSocial = "https://boletinoficial.rawson.gov.ar/lotears/611fc70805dc87000e84071f";
const urlIntermedia = "https://boletinoficial.rawson.gov.ar/lotears/611fcdd405dc87000e840721";


class LoteoSocial extends Component {
    
    constructor (props) {
      super(props)
      this.state = {
        infoLoteoSocial:'',      
        lotesVivienda:'',
        loading:true
      }   
    } 

   

    componentDidMount(){
      this.getLineas(); 
      scroll.scrollToTop()
    }  
      
      
    getLineas=async()=>{
        var infoLoteoSocial= await axios.get(urlSocial);
        var lotesVivienda= await axios.get(urlIntermedia);

        this.setState({
            infoLoteoSocial:infoLoteoSocial.data.Descripcion,
            lotesVivienda:lotesVivienda.data.Descripcion,
        })
    }    
       
    

  preInscripcion=()=>{
      this.setState({
          show:true,
      })
  }

  handleClose=e=>{
      this.setState({
          show:false
      })
  }

    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Loteo Social"
                                src={logoLotear}
                                width="30%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                          <h4 style={{marginTop:"3rem"}}>Información Loteo Social y Linea Intermedia</h4> 
                        </div>
                    </div>
                    <div className="row styleTextDesarrollo" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">


                          <div dangerouslySetInnerHTML={{__html: this.state.infoLoteoSocial}} />   
                
                             
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row styleTextDesarrollo" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">


                          <div dangerouslySetInnerHTML={{__html: this.state.lotesVivienda}} />   
                
                             
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>

                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 text-center">

                        <Link to="/lotear" className="linkNoneDec">
                            <Button style={{'background-color':'rgba(9,133,125,1)',marginBottom:"3rem"}} onClick={this.preInscripcion}>
                                Resultados de los Sorteos linea Social e Intermedia
                            </Button>
                        </Link>
                  

                      </div>
                      <div className="col-md-2"></div>
                      
                    </div>
                    
                </div>
            </React.Fragment>  
            
        )
    }
}

export default LoteoSocial;