import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import '../../pages/styles/Home.css';
import '../../pages/styles/Rentas.css';
import { GrDownload } from "react-icons/gr";
import ImpGif from "../../images/rentas/imp.gif"


class Impositivas extends Component {
  render() {
    return (
      <React.Fragment>

        <div className="container test">

          <div className="row justify-content-start" style={{ marginTop: 20 }}>
            <div className="col"></div>
            <div className="col-md-8">
              <h4>Aquí puedes descargar los documentos de de las ordenanzas impositivas a partir del año 2014</h4>
            </div>

            <div className="col-md-2">
              <img
                className="img-fluid mx-auto d-block"
                alt="Impositivas"
                src={ImpGif}
                width="90%"
              />
            </div>

            <div className="col"></div>
          </div>



          <div className="row align-items-center" style={{ marginTop: 50, borderTop: 'solid', borderWidth: 0.5 }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 8798-2023</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/Ord.Impositiva 8798.23.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, backgroundColor: '#bce5f0' }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 8559-2022</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA N° 8559 2022.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, borderWidth: 0.5 }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 8330-2021</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/Ordenanza-Impositiva-8330-2021.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, backgroundColor: '#bce5f0' }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 8081-2020</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%B0%208081%202020.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5 }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7994-2019</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/7994.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, backgroundColor: '#bce5f0' }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7954-2018</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207954%202018.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5 }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7954-2018 Anexo</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMP.%20N%C2%BA%207954%202018%20Anexo.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, backgroundColor: '#bce5f0' }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7856-2017</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207856-%202017.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5 }}>
            <div className="col"></div>

            < div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7755-2016</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207755-%202016.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5, backgroundColor: '#bce5f0' }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7616-2015</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207616-%202015.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>

          <div className="row align-items-center" style={{ marginTop: 5 }}>
            <div className="col"></div>

            <div className="d-inline p-1">
              <b style={{ fontSize: 15, fontFamily: 'Times New Roman' }}>ORD. IMPOSITIVA Nº 7491-2014</b>
            </div>
            <div className="d-inline p-1">
              <Button
                variant="info" style={{ borderRadius: 50 }}
                className="btn-block"
                onClick={() => window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207491-%202014.pdf', '_blank')}>
                <GrDownload size='1em' />
              </Button>
            </div>

            <div className="col"></div>
          </div>
          {/*
                  <div className="row align-items-center" style={{marginTop:5,backgroundColor:'#bce5f0'}}>
                      <div className="col"></div>
                    
                      <div className="d-inline p-1">
                        <b style={{fontSize:15,fontFamily:'Times New Roman'}}>ORD. IMPOSITIVA Nº 7351-2013</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207351-%202013.pdf','_blank')}>
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                    
                      <div className="col"></div>    
                  </div>

                  <div className="row align-items-center" style={{marginTop:5}}>
                      <div className="col"></div>
                    
                      <div className="d-inline p-1">
                        <b style={{fontSize:15,fontFamily:'Times New Roman'}}>ORD. IMPOSITIVA Nº 7208-2012</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSTIVA%20N%C2%BA%207208-%202012.pdf','_blank')}>
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                    
                      < div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5,backgroundColor:'#bce5f0'}}>
                      <div className="col"></div>
                      
                      <div className="d-inline p-1">
                          <b style={{fontSize:15,fontFamily:'Times New Roman'}}>ORD. IMPOSITIVA Nº 7097-2011</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%207097-%202011.pdf','_blank')}>
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                      
                      <div className="col"></div>
                  </div>

                  <div className="row align-items-center" style={{marginTop:5, marginBottom:10}}>
                      
                      <div className="col"></div>
                  
                      <div className="d-inline p-1">
                          <b style={{fontSize:15,fontFamily:'Times New Roman'}}>ORD. IMPOSITIVA Nº 6959-2010</b>
                      </div>
                      <div className="d-inline p-1">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/ordenanzas/ORD.IMPOSITIVA%20N%C2%BA%206959-%202010.pdf','_blank')}>
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                      
                      <div className="col"></div>
                  
                  </div>
                  */}
        </div>

      </React.Fragment>
    )
  }
}

export default Impositivas;