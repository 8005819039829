import React, { Component, Fragment } from 'react'
import './styles/Noticias.css';
import {animateScroll as scroll  } from 'react-scroll';

const apiUrl = 'https://boletinoficial.rawson.gov.ar';


class DesarrolloNotSec extends Component {

    constructor (props) {
        super(props)
        this.state = {
           noticiaSec:{},     
           noticia_sec_desarrollo:props.location.state          
        }    
    }
    componentDidMount(){scroll.scrollToTop();}

    render() {
        
        return (
            <Fragment>
                <div className="container-fluid">
                
                    <div className="row styleTextTitular">
                        
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                            <h4>{this.state.noticia_sec_desarrollo.titular}</h4>
                        </div>  
                        
                    </div>

                    <div className="row" >
                        <div className="col"></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Noticias"
                                src={apiUrl+this.state.noticia_sec_desarrollo.imagenDesarrollo[0].url}
                            />
                        </div>
                        <div className="col"></div>
                    </div>
                    
                    <div className="row styleTextDesarrollo">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div dangerouslySetInnerHTML={{__html: this.state.noticia_sec_desarrollo.desarrollo}} />
                        </div>   
                        <div className="col-md-1"></div>
                    </div>

                </div>
            </Fragment>
        
        )
    }
}

export default DesarrolloNotSec;