import React from 'react';

function Construccion() {
  return   ( <main role="main" style={{marginTop:"200px",marginBottom:"200px"}}>
  <div className="container" bis_skin_checked={1} >
    <section >
      <div className="row" bis_skin_checked={1} style={{display:"flex", justifyContent:"center"}}>
        <div className="col-md-8 col-md-offset-2 text-center" bis_skin_checked={1}>
          <h1><br />Página en construcción</h1>
          <p className="lead margin-40">La dirección web se encuentra en construcción
            <br />Te invitamos a contactarnos.</p>
          <p className="margin-60"><a href="/" className="btn btn-primary" style={{backgroundColor:"#4CB1AD"}}>Ir la página de inicio de Rawson.gov.ar</a></p>
        </div>
      </div>
    </section>
  </div>
</main>
  )
}

export default Construccion;
