import React from 'react';
import escudoMuni from '../images/escudoMuni.webp';
import logoModern from '../images/modernizacion.webp';
import './styles/Footer.css';

class FooterRw extends React.Component {
  render() {
    return (
        
      <footer className="rawsonFooter">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md"> <img src={escudoMuni} alt="Escudo Municipalidad de Rawson Chubut" width="150px"/> <small className="d-block my-3 text-muted">Municipalidad de Rawson - Chubut <br/> Mariano Moreno 650 <br/>© 2019-2022</small> </div>
          <div className="col-6 col-md">
           {/*--------*/}
          </div>
          
          <div className="col-6 col-md">
            <h5><b></b></h5>
            <ul className="list-unstyled text-small">
              <li>
              <div className="col-12 col-md"> <img src={logoModern} alt="Dirección Municipalidad de Rawson Chubut" width="150px"/> <small className="d-block my-3 text-muted">Municipalidad de Rawson - Chubut <br/> Mariano Moreno 650 <br/>© 2019-2022</small> </div>
              </li>
              <li>
               
              </li>
              <li>
             
              </li>
              <li>
                
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5><b></b></h5>
            <ul className="list-unstyled text-small">
              <li>
                
              </li>
              <li>
              
              </li>
              <li>
             
              </li>
              <li>
               
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5><b>Links Importantes</b></h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-Links" href="https://www.hcdrw.gov.ar/2019/">Concejo Deliberante</a>
              </li>
              <li>
                <a className="text-Links" href="http://www.ministeriodesalud.gov.ar/">Min. de Salud de la Provincia</a>
              </li>
              <li>
                <a className="text-Links" href="https://www.argentina.gob.ar/salud/">Min. de Salud de la Nación</a>
              </li>
              <li>
                <a className="text-Links" href="https://www.afip.gob.ar/sitio/externos/default.asp/">AFIP</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
           
     
            
     
   
    );
  }
}

export default FooterRw;