import React, { Component } from 'react';
import {Button} from 'react-bootstrap';

import './styles/Home.css';
import './styles/Rentas.css';

import { BiChurch,BiCoin } from "react-icons/bi";
import { FcCalculator, FcHome, FcPositiveDynamic } from "react-icons/fc";
import { FaCarSide, FaIdCard } from "react-icons/fa";
import { animateScroll as scroll  } from 'react-scroll';
import Impositivas from "../components/RentasComponents/Impositivas";
import IngBrutos from "../components/RentasComponents/IngBrutos";
import Comercio from "../components/RentasComponents/Comercio";
import Automotores from '../components/RentasComponents/Automotores';
import Inmobiliario from '../components/RentasComponents/Inmobiliario';
import Debito from '../components/RentasComponents/Debito';
import Cementerio from '../components/RentasComponents/Cementerio';

import logoLnc from "../images/logoLnc.webp";

class Rentas extends Component {


    constructor(props){
        super(props);
        this.state={
          loading:true,
          impositivas:true,
          ingbrutos:false,  
          comercio:false,
          automotores:false,
          inmobiliario:false,
          debito:false,
          cementerio:false
        } 
        
    }  

    componentDidMount(){  
        scroll.scrollToTop();
    }

    opcionRentas=(opcion)=>{

        if(opcion==='impositiva'){
            this.setState({
                ingbrutos:false,
                impositivas:true,
                comercio:false,
                automotores:false,
                inmobiliario:false,
                debito:false,
                cementerio:false
            })
        }else if(opcion==='ingbrutos'){
            this.setState({
                ingbrutos:true,
                impositivas:false,
                comercio:false,
                automotores:false,
                inmobiliario:false,
                debito:false,
                cementerio:false
            })
            
        }else if(opcion==='comercio'){
            this.setState({
                ingbrutos:false,
                impositivas:false,
                comercio:true,
                automotores:false,
                inmobiliario:false,
                debito:false,
                cementerio:false
            })
            
        }else if(opcion==='automotores'){
            this.setState({
                ingbrutos:false,
                impositivas:false,
                comercio:false,
                automotores:true,
                inmobiliario:false,
                debito:false,
                cementerio:false
            })
            
        }else if(opcion==='inmobiliario'){
            this.setState({
                ingbrutos:false,
                impositivas:false,
                comercio:false,
                automotores:false,
                inmobiliario:true,
                debito:false,
                cementerio:false
            })
            
        }else if(opcion==='cementerio'){
            this.setState({
                ingbrutos:false,
                impositivas:false,
                comercio:false,
                automotores:false,
                inmobiliario:false,
                debito:false,
                cementerio:true
            })
            
        }else if(opcion==='debito'){
            this.setState({
                ingbrutos:false,
                impositivas:false,
                comercio:false,
                automotores:false,
                inmobiliario:false,
                debito:true,
                cementerio:false
            })
            
        }

    }

    render() {
     

        return (
            <React.Fragment> 
               
                <div className="container-fluid">

                <div className="row headerTramites ">

               
                        <div className="col-md-2" style={{backgroundColor:'#bbeceb'}}>
                            <div className="row justify-content-center">    
                                <h4 style={{marginLeft:'100%',marginRight:'100%', marginTop:20}}>Trámites</h4>
                            </div>

                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}} className="btn-block" onClick={()=>this.opcionRentas('impositiva')}>Impostiva <FcPositiveDynamic size='1.5em'/> </Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('ingbrutos')}>Ingresos Brutos <FcCalculator size='1.5em'/></Button>
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('comercio')}>Comercio <BiCoin size='1.5em' color='yellow'/></Button>{' '}   
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1,marginBottom:'1.5rem'}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('automotores')}>Automotores <FaCarSide size='1.5em' color='#9c4517'/> </Button>{' '}
                            </div>
                            {/*                               
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('inmobiliario')}>Impuesto Inmobiliario <FcHome size='1.5em'/> </Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('debito')}>Débito Automático <FaIdCard size='1.5em'/> </Button>{' '}
                            </div>
                            <div className="row" style={{marginTop:10,marginLeft:5,marginRight:1,marginBottom:'1.5rem'}}>
                                <Button variant="info" style={{borderRadius:30}}  className="btn-block" onClick={()=>this.opcionRentas('cementerio')}>Cementerio <BiChurch size='1.5em' color='#d6d3da'/> </Button>{' '}
                            </div>
                            */}   

                            <div className="row justify-content-center" style={{marginBottom:'1.5rem'}}>
                                <img
                                    className="img-fluid mx-auto d-block"
                                    alt="Tramites"
                                    src={logoLnc}
                                    width="80%"
                                />
                            </div>

                          

                        </div>
           
                        <div className="col-md-10" >               

                            <div className="test">{this.state.impositivas && <Impositivas/>}</div>
                            <div>{this.state.ingbrutos && <IngBrutos />}</div>
                            {this.state.comercio && <Comercio/>}
                            {this.state.automotores && <Automotores/>}
                            {/*this.state.inmobiliario && <Inmobiliario/>*/}
                            {/*this.state.debito && <Debito/>*/}
                            {/*this.state.cementerio && <Cementerio/>*/}
                        </div>

                
                </div>  
                </div>

            </React.Fragment>      
        )
    }
}

export default Rentas;