import React, { Component } from 'react'
import {Card} from 'react-bootstrap';
const ReactMarkdown = require('react-markdown')


class boletinItem extends Component {
    render() {
        const {boletin} = this.props;
        return (
            
            <div>
            <Card style={{ padding: '1rem', border:'solid' }}>
                <ReactMarkdown source={boletin} />
            </Card>
            </div>
        )
    }
}


export default boletinItem;