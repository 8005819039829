import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import '../../pages/styles/Home.css';
import '../../pages/styles/Rentas.css';
import { GrDownload } from "react-icons/gr";
import { FaCarSide,FaBiking } from "react-icons/fa";
import Autos from "../../images/transito/autos.gif";


class C4 extends Component {
    render() {
        return (
            <React.Fragment> 
        
              <div className="container familiafade">    

                <div className="row justify-content-center" style={{marginTop:'1rem'}}>
                        <div className="col"></div>
                        <div className="col-md-8 text-center">
                          <h5>Autorización de los padres de menores.</h5>
                        </div>
                      
                        <div className="col-md-2">
                          <img
                                      className="img-fluid mx-auto d-block"
                                      alt="Transito"
                                      src={Autos}
                                      width="90%"
                          />                        
                        </div>

                        <div className="col"></div>
                  </div>


                  <div className="row justify-content-center" style={{border:'solid',borderColor:'#bce5f0',borderWidth:0.5,padding:'1rem'}}>

                      <div className="col-md-1 text-center"> <FaCarSide size='2em'/> </div>
                    
                      <div className="col-md-9 text-center">
                        <b style={{fontSize:16}}>Nota autorización de los padres de menores para autos.</b>
                      </div>

                      <div className="col-md-2 text-center">
                        <Button 
                          variant="info" style={{borderRadius:50}}
                          className="btn-block"
                          onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/autorizacion%20de%20los%20padres%20de%20memores%20para%20auto.png.png.jpeg','_blank')}>
                          <GrDownload size='1em'/> 
                        </Button>
                      </div>
                    
                      <div className="col"></div>
                  </div>

                  <div className="row justify-content-center" style={{marginTop:5,backgroundColor:'#bce5f0',padding:'1rem'}}>
                     
                      <div className="col-md-1 text-center"> <FaBiking size='2em'/> </div>
                      
                      <div className="col-md-9 text-center">
                       
                          <b style={{fontSize:16}}>Nota autorización de los padres de menores para motos.</b>
               
                      </div>  

                      <div className="col-md-2 text-center">
                          <Button 
                            variant="info" style={{borderRadius:50}}
                            className="btn-block"
                            onClick={()=>window.open('https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/autorizacion%20de%20los%20padres%20de%20memores%20para%20moto.png.png','_blank')}>
                            <GrDownload size='1em'/> 
                          </Button>
                       
                      </div>
                    
                    
                  </div>          
                </div>

            </React.Fragment>      
        )
    }
}

export default C4;