import React, { Component } from 'react';
import {Spinner,Image,Carousel} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';


class Recoleccion extends Component {

    constructor(props){
      super(props);
      this.state={
        loading:true,
      } 
    }  

    render() {
        return (
            <React.Fragment> 
        
              <div>    
                <div className="row justify-content-center test">
                  
                <div className="col"></div>      

                <div className="col-sm-12 col-md-10 col-lg-8 text-center">
                  {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}
                  <div style={this.state.loading ? {display: 'none'} : {}}>
                    <Carousel className="shadow p-3 mb-5 bg-white rounded" fade style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_1_caratula_1435542ff3.jpg?6623085.4','_blank')}
                          onLoad={()=>this.setState({loading:false})}
                          className="w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_1_caratula_1435542ff3.jpg?6623085.4"
                          
                          alt="First slide"
                        />
                        <Carousel.Caption>
                      
                        </Carousel.Caption>
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_3_manejo_especial_48cf89ba4a.jpeg?6747512.6', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_3_manejo_especial_48cf89ba4a.jpeg?6747512.6"
                          alt="Second slide"
                        />

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_5_HORARIOS_Y_RECORRIDO_13_d6cc48f2e4.jpg?6903592', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_5_HORARIOS_Y_RECORRIDO_13_d6cc48f2e4.jpg?6903592"
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_6_TIPOS_DE_RESIDUO_3_B_77f558485b.jpg?6947567', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_6_TIPOS_DE_RESIDUO_3_B_77f558485b.jpg?6947567"
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>

                      <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_2_BOLSAS_c07320e4bd.jpg?6991910.800000001', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_2_BOLSAS_c07320e4bd.jpg?6991910.800000001"
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>

                        <Carousel.Item>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_4_acceso_a_girsu_28028e820b.jpg?7038847.1', '_blank')}
                          className="d-block w-100"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_4_acceso_a_girsu_28028e820b.jpg?7038847.1"
                          alt="Third slide"
                        />

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>

                    </Carousel>
                  </div>

                </div>

                <div className="col"></div> 

                </div>
              </div>

            </React.Fragment>      
        )
    }
}

export default Recoleccion;