import React, { Component } from 'react';
import gifTramites from '../images/tramites.webp';
import rentas from '../images/tramites/rentas.webp';
import familia from '../images/tramites/familia.webp';
import transito from '../images/tramites/transito.webp'
import empleado from '../images/tramites/empleado.webp';
import macro from '../images/tramites/macro.webp';    
import contribuyente from '../images/tramites/contribuyente.webp';
import produccion from '../images/tramites/secAmbiente.webp'
import op from '../images/tramites/op.webp';

import {Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/Home.css';


class GuiaTramites extends Component {
    render() {
        return (
        
            <React.Fragment> 
                <div className="container-fluid"> 
                    <div className="row headerTramites">
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTramites}
                                width="15%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                            <h4 style={{marginTop:"3rem"}}>Guía de Trámites</h4>
                        </div>
                    </div>  
                </div>
                


                <div className="container-fluid palacio">
                    
                    <div className="col-lg-12"> 

                    <div className="row">
                        <div className="col-md-3 cardPadding">
                            
                            <Card className="cardAlto">
                                <Card.Header className = "BtncardInfo text-center">
                                    <h5>Consultas y Pago de Impuestos Online</h5>
                                </Card.Header>
                                <Card.Body>    
                                    <Card.Title className="contenedor">
                                        <a href={"https://isurgob.net/sigobweb/"}>
                                            <img
                                                className="img-fluid img-tramite"
                                                alt="Tramites"
                                                src={contribuyente}
                                            />          
                                        </a>                  
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        
                        </div>
                            <div className="col-md-3 cardPadding">

                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Dirección de Rentas</h5>
                                    </Card.Header>

                                    <Card.Body>
                                        
                                        <Card.Title className="contenedor">
                                            <Link to="/rentas" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={rentas}
                                                />        
                                            </Link>            
                                        </Card.Title>
                                            
                                    </Card.Body>

                                </Card>
                                
                            </div>
                            
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Secretaría de Ambiente Producción...</h5>
                                    </Card.Header>

                                    <Card.Body >
                                        
                                        <Card.Title className="contenedor">
                                            <Link to="/produccion" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={produccion}
                                                /> 
                                            </Link>                   
                                        </Card.Title>
                                            
                                    </Card.Body>
                                    
                                </Card> 
                            </div>
                            
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Secretaría de Familia</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <Link to="/tramites_familia" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={familia}
                                                />          
                                            </Link>                  
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Dirección de Tránsito y Transporte</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <Link to="/transito" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={transito}
                                                />          
                                            </Link>                     
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Servicio al Empleado Municipal</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                                       
                                            <Link to="/servicio_empleado" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={empleado}
                                                />          
                                            </Link>                     

                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Pagá los impuestos online a través de Banco Macro</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <a target="_blank" rel="noopener noreferrer" href={"https://tramites.rawson.gov.ar/inicio.html"}>
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={macro}
                                                />          
                                            </a>                  
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            
                            </div>    
                            <div className="col-md-3 cardPadding">
                                <Card className="cardAlto">
                                    <Card.Header className = "BtncardInfo text-center">
                                        <h5>Unidad Ejecutora Municipal</h5>
                                    </Card.Header>
                                    <Card.Body>    
                                        <Card.Title className="contenedor">
                                            <Link to="/tramites_op" className="linkNoneDec">
                                                <img
                                                    className="img-fluid img-tramite"
                                                    alt="Tramites"
                                                    src={op}
                                                />          
                                            </Link>                  
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>   
                        </div>
                    </div>
                </div>


            </React.Fragment>  
            
        )
    }
}

export default GuiaTramites;