import React, { Component } from 'react';
import {Spinner,Image} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';
import axios from 'axios';
const ReactMarkdown = require('react-markdown')


class RadioAmbiental extends Component {
    
    constructor(props){
      super(props);
      this.state={
        loading:true,
        textRadio:'cargando...'
      } 
    }  

    componentDidMount() {
      this.getRadio();
    }

    getRadio = async ()=>{
      var dataRAdio = await axios.get('https://boletinoficial.rawson.gov.ar/produccion-programa-radio-ambientas');
      this.setState({textRadio:dataRAdio.data[0].descripcion})
    }


    render() {
        return (
            <React.Fragment> 
        
              <div>    

                  <div className="row justify-content-center test" style={{marginBottom:'0.5rem'}}>

                    <div className="col"></div>

                    <div className="col-md-8 col-lg-6 text-center">
                      {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}

                      <div style={this.state.loading ? {display: 'none'} : {}}>
                        <div style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>
                          <Image
                            onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/medium_Whats_App_Image_2022_07_05_at_1_16_14_PM_126dc4775a.jpeg', '_blank')}
                            onLoad={()=>this.setState({loading:false})}
                            className="d-block w-100 shadow p-3 mb-5 bg-white rounded"
                            src="https://boletinoficial.rawson.gov.ar/uploads/small_Whats_App_Image_2022_07_05_at_1_16_14_PM_126dc4775a.jpeg"
                            alt="First slide"
                          />
                        </div>
                      </div> 

                    </div>

                    <div className="col"></div>

                  </div>

                  <div className="row">
                    <div className="col"></div>
                    <div className="col-md-10 col-lg-10 text-left" style={{marginBottom:'4rem'}}>
                      <ReactMarkdown source={this.state.textRadio} />
                    </div>
                    <div className="col"></div>
                  </div>

                

                
              </div>

            </React.Fragment>      
        )
    }
}

export default RadioAmbiental;