import React, { Component } from 'react';
import {Spinner,Image} from 'react-bootstrap';
import '../../pages/styles/Home.css'
import '../../pages/styles/Rentas.css';
import axios from 'axios';
const ReactMarkdown = require('react-markdown')


class CertificadoYArbol extends Component {
    
    constructor(props){
      super(props);
      this.state={
        loading:true,
        textDesarrollo:'cargando...'
      } 
    }  

    componentDidMount() {
      this.getRadio();
    }

    getRadio = async ()=>{
      var dataDesarrollo = await axios.get('https://boletinoficial.rawson.gov.ar/produccion-novedades/63bc033b580a392f3b29709a');
      this.setState({textDesarrollo:dataDesarrollo.data.desarrollo})
    }


    render() {
        return (
            <React.Fragment> 
        
              <div>    

                 <div className="row justify-content-center test" style={{marginBottom:'0.5rem'}}>

                  <div className="col"></div>

                  <div className="col-md-8 col-lg-6 text-center">
                    <h3>Novedades Secretaria de Ambiente, Producción y Desarrollo Sustentable</h3>
                  </div>

                  <div className="col"></div>

                </div>

                <div className="row justify-content-center test" style={{marginBottom:'0.5rem'}}>
                 
                  <div className="col"></div>

                  <div className="col-md-8 col-lg-6 text-center">
                    {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}

                    <div style={this.state.loading ? {display: 'none'} : {}}>
                      <div style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>
                        <Image
                          onLoad={()=>this.setState({loading:false})}
                          className="d-block w-100 shadow p-3 mb-5 bg-white rounded"
                          src="https://boletinoficial.rawson.gov.ar/uploads/PODA_DE_ARBOLES_FRUTALES_FN_a05337e32b.jpg"
                          alt="First slide"
                        />
                      </div>
                    </div> 

                  </div>

                  <div className="col"></div>

                </div>

                <hr></hr>

                <div className="row justify-content-center test" style={{marginBottom:'0.5rem'}}>
                 
                  <div className="col"></div>

                  <div className="col-md-8 col-lg-6 text-center">
                    {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}

                    <div style={this.state.loading ? {display: 'none'} : {}}>
                      <div style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/certificado_e93b804ab3.pdf', '_blank')}
                          onLoad={()=>this.setState({loading:false})}
                          className="d-block w-100 shadow p-3 mb-5 bg-white rounded"
                          src="https://boletinoficial.rawson.gov.ar/uploads/small_certificado_61d34294ba.png"
                          alt="First slide"
                        />
                      </div>
                    </div> 

                  </div>

                  <div className="col"></div>

                </div>

                <div className="row">
                  <div className="col"></div>
                  <div className="col-md-10 col-lg-10 text-left" style={{marginBottom:'4rem'}}>
                    <ReactMarkdown source={this.state.textDesarrollo} />
                  </div>
                  <div className="col"></div>
                </div>     


                <hr></hr>


                <div className="row justify-content-center test" style={{marginBottom:'0.5rem'}}>

                  <div className="col"></div>

                  <div className="col-md-8 col-lg-6 text-center">
                    {this.state.loading&&<Spinner animation="border" variant="success" style={{marginTop:'1rem',marginBottom:'1rem'}}/>}

                    <div style={this.state.loading ? {display: 'none'} : {}}>
                      <div style={{marginTop:'1.5rem',marginBottom:'0.5rem'}}>
                        <Image
                          onClick={()=>window.open('https://boletinoficial.rawson.gov.ar/uploads/large_una_V_Ida_Un_Arbol_13c1906291.jpeg', '_blank')}
                          onLoad={()=>this.setState({loading:false})}
                          className="d-block w-100 shadow p-3 mb-5 bg-white rounded"
                          src="https://boletinoficial.rawson.gov.ar/uploads/medium_una_V_Ida_Un_Arbol_13c1906291.jpeg"
                          alt="First slide"
                        />
                      </div>
                    </div> 

                  </div>

                  <div className="col"></div>

                </div>

              </div>

            </React.Fragment>      
        )
    }
}

export default CertificadoYArbol;