import React, { Component } from 'react';
import gifTarmites from '../../../images/tramites.webp';
import {Card, Button, Accordion} from 'react-bootstrap';
import '../../styles/Home.css';

class GuiaTramites extends Component {
    render() {
        return (
        
            <React.Fragment>    
                <div className="container-fluid">
                    <div className="row" style={{background:'white'}}>
                        <div className="col-md-6">
                            <img
                                className="img-fluid mx-auto d-block"
                                alt="Tramites"
                                src={gifTarmites}
                                width="30%"
                            />
                        </div>
                        <div className="col-md-6 text-center">
                        
                                <h4 style={{marginTop:"3rem"}}> Guía de Trámites Dirección de Tránsito y Transporte</h4>
                            
                        </div>
                    </div>
                    <div className="row" style={{'margin-top':'5rem','margin-bottom':'5rem'}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h5>Requisitos para la licencia particular y profesional clase A-B1-B2-C1-C2-C3-E1-E2-F-G1-G2-G3</h5>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <ul>
                                            <li><p>Presentar DNI y presentar copia del mismo donde conste datos personales y útimo domicilio actualizado.</p></li>
                                            <li><p>Certificado de antecedentes de tránsito extendido por REPAT (Terminal de ómnibus Rawson)</p></li>
                                            <li><p>Libre de deuda de infracciones por el tribunal de faltas (Av. Sarmiento N°974)</p></li>
                                            <li><p>Carnet anterior en mano - certificado autent. de origen</p></li>
                                            <li><p>Grupo sanguíneo</p></li>
                                            <li><p>Número de CUIL</p></li>
                                        </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                              
                            </Accordion>

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h5>Requisitos para la licencia particular y profesional D1-D2-D3-D4</h5>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <ul>
                                                <li><p>Presentar DNI y presentar copia del mismo donde conste datos personales y útimo domicilio actualizado.</p></li>
                                                <li><p>Certificado de antecedentes de tránsito extendido por REPAT (Terminal de ómnibus Rawson)</p></li>
                                                <li><p>Libre de deuda de infracciones por el tribunal de faltas (Av. Sarmiento N°974)</p></li>
                                                <li><p>Carnet anterior en mano - certificado autent. de origen</p></li>
                                                <li><p>Certificado de reincidencia psicológica cat. D-1-D-2-D-3</p></li>
                                                <li><p>Grupo sanguíneo</p></li>
                                                <li><p>Número de CUIL</p></li>
                                            </ul>
                                      
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                
                            </Accordion>
                
                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h5>Categoría de licencia de conducir clases y subclases</h5>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <ul>
                                                <li>
                                                    <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/categoria de licencia de conducir clases y subclase.jpeg">
                                                        <p>Ver Categoría de licencia de conducir clases y subclases</p> 
                                                    </a>
                                                </li>
                                            </ul>
                                      
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                               
                            </Accordion>

                            <Accordion>
                                <Card>
                                    
                                    <Accordion.Toggle as={Button} variant="link" className="noLink backHeader" eventKey="0">
                                        <Card.Header className="text-center" >
                                            <h5>Autorización de los padres de menores</h5>
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <ul>
                                                <li>
                                                    <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/autorizacion de los padres de memores para auto.png.png.jpeg">
                                                        <p>Nota autorización de los padres de menores para autos.</p>
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <a className="bodyCard" target="_blank" rel="noopener noreferrer" href="https://apiadmin.rawson.gov.ar/Documentacion_Tramites/Transito/autorizacion de los padres de memores para moto.png.png">
                                                        <p>Nota autorización de los padres de menores para motos.</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                               
                            </Accordion>
                             
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </React.Fragment>  
            
        )
    }
}

export default GuiaTramites;