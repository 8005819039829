import React from 'react';
import {Card, Button, Container, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VideoRawsonTurismo from '../components/VideoRawsonTurismo';
import CarruselNoticias from '../components/CarruselNoticias';
import Banner_Header_comp from'../images/loadComprimidos/bannerHeaderN.webp';
import Banner_Header from'../images/home/Banner_Header.gif';
import NoticiaSecundaria from '../components/NoticiaSecundaria';
import './styles/Home.css';

const apiUrl = 'https://boletinoficial.rawson.gov.ar';

class Home extends React.Component {
  
  constructor(props){
    super(props);

    this.state={
      loading:true,
      loadingImp:true,
      bannerHeader:'',
      BannerRadio:'',
      noticiasSecundarias:[],
      noticiasCarrusel:[],
      dataHome:{},
      arrayNoticia:[]
    }
    this.distCol='';
  }  

  componentDidMount(){

    switch (this.props.noticiasSecundarias.length) {
      case 1:
        this.distCol = 'col-md-12 cardPadding';
        break;
      case 2:
        this.distCol = 'col-md-6 cardPadding';
        break;
      case 3:
        this.distCol = 'col-md-4 cardPadding';
        break;
      case 4:
        this.distCol = 'col-md-6 cardPadding';
        break;
      case 5:
        this.distCol = 'col-md-4 cardPadding';
        break;
      case 6:
        this.distCol = 'col-md-4 cardPadding';
        break;      
      default:
        this.distCol = 'col-md-4 cardPadding';
        break;
    }
    this.setState({
      dataHome:this.props.dataHome.data,   
      noticiasCarrusel:this.props.noticiasCarrusel.data,
    })    
    
  }

  render() {

      return (
        <div className="fadeHomeInicio"> 
          <div className="containerCustom">
            {/*
            <div className="container-fluid btn-coronavirus">
              <a target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}} href={this.props.dataHome.data.url_alerta_informativa}>
                <h5 className="textInfoCoronavirus">
                  <strong>INFORMACIÓN:</strong> 
                  {this.props.dataHome.data.titular_alerta_informativa}
                </h5>
              </a>
            </div>
            */}
            <div className="container-fluid">

              <div className="row bannerDist">

                {
                  this.state.loading &&

                  <Image
                    className="img-fluid"
                    rounded='true'
                    alt="Banner Principal Municipalidad de Rawson Chubut" 
                    src={Banner_Header_comp}
                  />
                } 

                <div style={this.state.loading ? {display: 'none'} : {}}>
                  <Image
                    className="img-fluid"
                    rounded='true'
                    alt="Banner Principal Municipalidad de Rawson Chubut"
                    onLoad={()=>{this.setState({loading:false})}}
                    src={Banner_Header}
                  />
                </div>
              </div>

            </div>
                
            <div className="wrapper cabecera">
            
              <div className="box d cabecera2_a">
                <CarruselNoticias noticiasCarrouselData={this.props.noticiasCarrusel.data}/>
              </div>
    
              <div className="box f cabecera2_b">
                <a href={this.props.dataHome.data.link_banner}>
                  { this.state.loadingImp &&
                      <Image
                        style={{padding:'0.1rem'}} 
                        width="100%"
                        alt="Pago impuestos online"
                        src={require('../images/loadComprimidos/pago_online_load.webp')}
                      />
                  }

                  <div style={this.state.loadingImp ? {display: 'none'} : {}}>  
                    <Image
                      style={{padding:'0.1rem'}} 
                      height="100%"
                      width="100%"
                      alt="Pago impuestos online"
                      onLoad={()=>{this.setState({loadingImp:false})}}
                      src={apiUrl+this.props.dataHome.data.BannerSecundario[0].url}
                    />               
                  </div>

                </a>        
              </div>
    
              <div className="box c cabecera1">
                <VideoRawsonTurismo 
                  urlVideo = {this.props.dataHome.data.url_video} 
                  ancho="100%" 
                  alto="18rem" 
                />
              </div>
        
            </div>

            

            <div className="container-fluid puente">    

              <div className="container-fluid">
               {/*  
              <div className="row">
               
                <div className="col-md-12" align="center" style={{'padding':'0.5rem'}}>
                  <Card>
                    <Card.Header className = "BtncardInfo">
                      <h5>Novedades para Contribuyentes de Ingresos Brutos</h5>
                    </Card.Header>
                      <Card.Body>
                        <Card.Title></Card.Title>
                        <Card.Text>
                          Se informa que el Vencimiento de la Declaración Jurada ANUAL 2022 es el dia 31/03/2023.
                        </Card.Text>
                        <Link to={`/rentas`}>
                          <Button className = "BtncardInfo" variant="info">
                            VER
                          </Button>
                        </Link>
                      </Card.Body>
                  </Card>
                </div>
                
              </div>
              */}    
              </div>

              <div className="col-lg-12"> 
                <div className="row">
                  {                  
                    this.props.noticiasSecundarias.map((noticia) =>(
                      <Container className = {this.distCol} key={noticia.id}>
                          <NoticiaSecundaria noticia = {noticia}/>
                      </Container>
                    )) 
                  }         
                </div>
              </div>
            </div>
        
            <div className="container-fluid">
              <div className="row posAyudanos">
                <div className="col-md-12" align="center">
                  <Card>
                    <Card.Header className = "BtncardInfo">
                    </Card.Header>
                      <Card.Body>
                        <Card.Title>Queremos estar cerca tuyo.</Card.Title>
                        <Card.Text>
                          Escuchamos a los vecinos e interpretamos sus demandas y necesidades.
                          De esta manera, damos seguimiento a sus solicitudes a través de políticas de cercanía.
                        </Card.Text>
                        <Link to={`/telefono`}>
                          <Button className = "BtncardInfo" variant="info">
                            TELÉFONOS Y DIRECCIONES
                          </Button>
                        </Link>
                      </Card.Body>
                  </Card>
                </div>
              </div>
            </div>  

            <div className="container-fluid tonina">
              <div className="col-lg-12"> 
                <div className="row rowAltura">
                </div>  
              </div>
            </div>

          </div>      
        </div>
      ); 
  }
}

export default Home;
