import React,{useState} from 'react'
import axios from 'axios';
import {Spinner} from 'react-bootstrap';
import { urlApi } from '../../utils/api';
export const ControlProcess = () => {

  const [files, setFiles]             = useState([]);
  const [loading, setLoading]         = useState(false);
  const [verDescargaZip,SetVerDescargaZip] = useState(false);
  const [resultFiles, setResultFiles] = useState({'proceso': 'terminado', 'archivos_correctos': [], 'archivos_incorrectos': []});  
  let archivosAsubir=React.createRef();  

  function handleChangeSubirArchivos(e){
  
    if(e.target.files[0]){
      var archivos = null;
      archivos = e.target.files;
      setFiles(archivos);  
    }
    
  }  

  async function handleUploadArchivos(e){

    e.preventDefault();

    const fd = new FormData();
    let extensionError = false;

    for(let i=0;i<files.length;i++){
      let fileArray = files[i].name.split('.');

      if(fileArray[fileArray.length-1]=='xlsx'){
        fd.append('files',files[i]) 
      }else{
        extensionError = true;
      }
    }


    if(!extensionError){
      await axios.post(urlApi+"/uploadFilesiibb", fd, {
          headers:{
              'accept': 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          }
      })
      .then((response) => {      
          setLoading(false);
          alert('Archivos Subidos Correctamente')
      }).catch((error) => {
          alert("HUBO UN ERROR AL SUBIR LOS ARCHIVOS INTENTE NUEVAMENTE :(");
      });           
    }else{
      alert("LOS ARCHIVOS A SUBIR DEBEN TENER EXTENSION '.xlsx'")
    }
    
  } 

  async function handleConvertir(){
    archivosAsubir.current.value="";
    let result = await axios.get(urlApi+"/iibb/process");
    if(result.data.archivos_correctos.length>0){SetVerDescargaZip(true)};
    setResultFiles(result.data);
  }


  return (
    <>
        <div className="row justify-content-start" style={{marginTop:20,marginBottom:20}}>
   
                <div className="col"></div>
                <div className="col-md-5">
                    <form onSubmit={handleUploadArchivos}>
                        <div class="form-group">
                            <label for="exampleFormControlFile1">Seleccionar los archivo a convertir</label>
                            <input 
                                type="file"
                                ref={archivosAsubir}
                                class="form-control-file" 
                                id="archivosAsubir" 
                                multiple
                                onChange={(e)=>handleChangeSubirArchivos(e)}
                            />
                        </div>

                        {
                          loading
                          ?
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden"></span>
                            </Spinner>
                          :
                            <button type="submit" className="btn btn-info">Subir Archivos</button>
                        }   
                    </form>
                </div>
            
                <div className="col-md-5">
                  <div className="row justify-content-center" style={{marginBottom:20}}>
                    <button 
                      className="btn btn-info"
                      onClick={handleConvertir}
                    >
                      Convertir
                    </button>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-4 text-center">
                  
                      <table class="table table-striped">
                        <thead>
                          <tr>                          
                            <th scope="col">Archivos Correctos</th>
                          </tr>
                        </thead>

                        <tbody>
                          {
                            resultFiles.archivos_correctos.map(nombreArchivo =>(
                              <tr key={nombreArchivo}>
                                  <td>
                                    {nombreArchivo}
                                  </td>
                              </tr>
                            ))            
                          }
                        </tbody>
                      </table>

                      {
                        verDescargaZip &&
                        <a href={urlApi+'/iibb/outputFiles/'+resultFiles.zipFile+'.zip'} download>
                          Descargar zip con todos los archivos
                        </a>
                      } 
                    </div>
                    <div className="col-md-8 text-center">
                   
                      <table class="table table-striped">
                        <thead>
                          <tr>
                           
                            <th scope="col">Archivos Incorrectos</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {
                            resultFiles.archivos_incorrectos.map(nombreArchivo =>(
                              <tr key={nombreArchivo}>
                                <td>
                                  {nombreArchivo}
                                </td>
                              </tr>
                            ))            
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div className="col"></div>

           
        </div>
    </>
  )
}
